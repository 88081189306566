import { Box, InputBase, InputLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import ClientLineChart from "../Common/ClientLineChart";

const styles = {
    label: {
        color: "#404040",
        fontSize: "13px",
        fontWeight: 500,
    },
    inputBase: {
        borderRadius: "20px",
        height: "40px",
        mb: 1,
        textAlign: "right",
        alignItems: "right",
    },
};

function Graph({ totalExpense, rndExpenseCumulative, date, symbol }) {
    const [formattedDays, setFormattedDays] = useState([]);
    const [totalExpensesSum, setTotalExpensesSum] = useState("0");
    const [rndExpenseSum, setRndExpenseSum] = useState("0");
    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        if (symbol) {
            setCurrency(new Intl.NumberFormat('en-US', { style: "currency", currency: symbol }));
        }
    }, [symbol]);

    useEffect(() => {
        if (currency) {
            const totalExpenseSums = totalExpense?.reduce((acc, expense) => acc + expense, 0) || 0;
            const rndExpenseSums = rndExpenseCumulative?.reduce((acc, expense) => acc + expense, 0) || 0;

            const formattedTotal = currency.format(totalExpenseSums).replace(/[^$€£¥0-9,.]/g, '');
            const formattedRnd = currency.format(rndExpenseSums).replace(/[^$€£¥0-9,.]/g, '');

            setTotalExpensesSum(formattedTotal);
            setRndExpenseSum(formattedRnd);
        }
    }, [currency, totalExpense, rndExpenseCumulative]);

    const changeDatesFormat = (dates) => {
        const hash = {
            "1": "Jan",
            "2": "Feb",
            "3": "Mar",
            "4": "Apr",
            "5": "May",
            "6": "Jun",
            "7": "Jul",
            "8": "Aug",
            "9": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec"
        };
        const changedDates = dates?.map(d => `${hash[d.month]}-${d.year}`);
        setFormattedDays(changedDates);
    };

    useEffect(() => {
        changeDatesFormat(date);
    }, [date]);

    return (
        <Box sx={{ borderTop: "1px solid #E4E4E4", p: 1 }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                    gap: 2,
                    px: 1,
                }}
            >
                <Box>
                    <InputLabel sx={styles.label}>Total Expenses</InputLabel>
                    <InputBase
                        type="text"
                        sx={styles.inputBase}
                        value={totalExpensesSum || ""}
                        disabled
                    />
                </Box>
                <Box>
                    <InputLabel sx={styles.label}>R&D Expenses</InputLabel>
                    <InputBase
                        type="text"
                        sx={styles.inputBase}
                        value={rndExpenseSum || ""}
                        disabled
                    />
                </Box>
            </Box>
            <Box sx={{ px: 1 }}>
           
                <ClientLineChart
                    symbol={symbol}
                    totalExpense={totalExpense}
                    rndExpenseCumulative={rndExpenseCumulative}
                    date={formattedDays}
                />
            </Box>
        </Box>
    );
}

export default Graph;



// import { Box, InputBase, InputLabel } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import ClientLineChart from "../Common/ClientLineChart";

// const styles = {
//     label: {
//         color: "#404040",
//         fontSize: "13px",
//         fontWeight: 500,
//     },
//     inputBase: {
//         borderRadius: "20px",
//         height: "40px",
//         // border: "1px solid #E4E4E4",
//         // pl: 1,
//         mb: 1,
//         textAlign: "right",
//         alignItems: "right",
//     },
// };

// function Graph({ totalExpense, rndExpenseCumulative, date, symbol }) {
//     const [formattedDays, setFormattedDays] = useState([]);
//     const [totalExpensesSum, setTotalExpensesSum] = useState("0");
//     const [rndExpenseSum, setRndExpenseSum] = useState("0");
//     const [currency, setCurrency] = useState(null);

//     useEffect(() => {
//         if (symbol) {
//             setCurrency(new Intl.NumberFormat('en-US', { style: "currency", currency: symbol }));
//         }
//     }, [symbol]);

//     useEffect(() => {
//         if (currency) {
//             const totalExpenseSums = totalExpense?.reduce((acc, expense) => acc + expense, 0) || 0;
//             const rndExpenseSums = rndExpenseCumulative?.reduce((acc, expense) => acc + expense, 0) || 0;

//             setTotalExpensesSum(currency.format(totalExpenseSums));
//             setRndExpenseSum(currency.format(rndExpenseSums));
//         }
//     }, [currency, totalExpense, rndExpenseCumulative]);

//     const changeDatesFormat = (dates) => {
//         const hash = {
//             "1": "Jan",
//             "2": "Feb",
//             "3": "Mar",
//             "4": "Apr",
//             "5": "May",
//             "6": "Jun",
//             "7": "Jul",
//             "8": "Aug",
//             "9": "Sep",
//             "10": "Oct",
//             "11": "Nov",
//             "12": "Dec"
//         };
//         const changedDates = dates?.map(d => `${hash[d.month]}-${d.year}`);
//         setFormattedDays(changedDates);
//     };

//     useEffect(() => {
//         changeDatesFormat(date);
//     }, [date]);

//     return (
//         <Box sx={{ borderTop: "1px solid #E4E4E4", p: 1 }}>
//             <Box
//                 sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     mt: 1,
//                     gap: 2,
//                     px: 1,
//                 }}
//             >
//                 <Box>
//                     <InputLabel sx={styles.label}>Total Expenses</InputLabel>
//                     <InputBase
//                         type="text"
//                         sx={styles.inputBase}
//                         value={totalExpensesSum || ""}
//                         disabled
//                     />
//                 </Box>
//                 <Box>
//                     <InputLabel sx={styles.label}>R&D Expenses</InputLabel>
//                     <InputBase
//                         type="text"
//                         sx={styles.inputBase}
//                         value={rndExpenseSum || ""}
//                         disabled
//                     />
//                 </Box>
//             </Box>
//             <Box sx={{ px: 1 }}>
//                 <ClientLineChart
//                     symbol={symbol}
//                     totalExpense={totalExpense}
//                     rndExpenseCumulative={rndExpenseCumulative}
//                     date={formattedDays}
//                 />
//             </Box>
//         </Box>
//     );
// }

// export default Graph;
