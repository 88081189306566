
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [documents, setDocuments] = useState([]);
  const [docFilterState, setDocFilterState] = useState({
    companyId: [],
    projectId: "",
    document: "",
    company: "",
    project: "",
  });
  const [clearFilterTrigger, setClearFilterTrigger] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const triggerClearFilters = () => {
    setClearFilterTrigger((prev) => !prev);
  };
  const { logout } = useAuthContext();

  const fetchDocuments = async (options = {}) => {
    const queryParams = new URLSearchParams();
    if (options.companyIds) queryParams.append("companyIds", JSON.stringify(options.companyIds));
    if (options.documentId) queryParams.append("documentId", options.documentId);
    if (options.relatedTo) queryParams.append("relatedTo", options.relatedTo);
    if (options.relationId) queryParams.append("relationId", options.relationId);
    if (options.documentType) queryParams.append("documentType", options.documentType);

    const queryString = queryParams.toString();
    const company = options.relatedTo == 'clients' ? options.relationId : null;
    const url = `${BaseURL}/api/v1/documents/${localStorage.getItem("userid")}/get-docs${queryString ? `?${queryString} &project=${options.relationId} &company = ${company} ` : ""
      }`;

    try {
      const response = await axios.get(url, Authorization_header());
      setDocuments(response?.data?.data);
    } catch (error) {
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <DocumentContext.Provider
      value={{
        documents,
        fetchDocuments,
        docFilterState,
        setDocFilterState,
        triggerClearFilters,
        clearFilterTrigger,
        isFilterApplied,
        setIsFilterApplied,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
