import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const headerCellStyle = {
  fontSize: "13px",
  // fontWeight: 600,
  borderLeft: "1px solid #ddd",
  whiteSpace: "nowrap",
  py: 1,
  textAlign: "center",
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#ececec",
};

const headerRowStyle = {
  backgroundColor: "rgba(64, 64, 64, 0.1)",
};

function TableHeader2({ tableData, page }) {
  return (
    <>
      <TableHead>
        <TableRow sx={headerRowStyle}>
          {tableData.columns.map((column, index) => (
            <TableCell
              key={index}
              sx={{
                ...headerCellStyle,
                textAlign:
                  (page === "alerts" || page === "document") &&
                    (index === 0 || index === 1)
                    ? "left"
                    : index === 0
                      ? "left"
                      : "center",
              }}
            >
              {column}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default TableHeader2;
