import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TimesheetContext } from "../../context/TimesheetContext";
import CompanySelector from "../FilterComponents/CompanySelector";
import MonthSelector from "../FilterComponents/MonthSelector.";
import SliderInput from "../FilterComponents/SliderInput";
import { MonthNames } from "../../constants/Months";

function TimesheetFilters({ clientData }) {
  const {
    timesheetFilterState,
    setTimesheetFilterState,
    clearTimesheetFilterTrigger,
  } = useContext(TimesheetContext);
  const [company, setCompany] = useState(timesheetFilterState.company);
  const [monthName, setMonthName] = useState(timesheetFilterState.monthName);

  const handleFilterChange = (field) => (event, newValue) => {
    const value = newValue ?? event.target.value;
    setTimesheetFilterState({
      ...timesheetFilterState,
      [field]: value,
    });
  };
  useEffect(() => {
    setTimesheetFilterState({
      ...timesheetFilterState,
      companyId: [
        clientData?.find((client) => client?.companyName === company)
          ?.companyId,
      ],
      company: company,
    });
  }, [company]);
  useEffect(() => {
    setTimesheetFilterState({
      ...timesheetFilterState,
      month: [monthName],
      monthName: monthName,
    });
  }, [monthName]);
  useEffect(() => {
    if (clearTimesheetFilterTrigger) {
      setCompany("");
      setMonthName("");
      setTimesheetFilterState({
        ...timesheetFilterState,
        companyId: [],
        company: "",
        monthName: "",
        month: [],
        nonRnDHours: [0, 5000],
        rnDHours: [0, 5000],
        uncertainHours: [0, 5000],
        reconciledHours: [0, 5000],
      });
    }
  }, [clearTimesheetFilterTrigger]);

  return (
    <Box>
      <CompanySelector
        clients={clientData}
        company={company}
        setCompany={setCompany}
      />
      <MonthSelector
        monthNames={MonthNames}
        month={monthName}
        setMonth={setMonthName}
      />
      <SliderInput
        minWidth={220}
        label="Non R&D Hours"
        value={timesheetFilterState?.nonRnDHours}
        onChange={handleFilterChange("nonRnDHours")}
        min={0}
        max={100000}
      />
      <SliderInput
        minWidth={220}
        label="R&D Hours"
        value={timesheetFilterState?.rnDHours}
        onChange={handleFilterChange("rnDHours")}
        min={0}
        max={100000}
      />
      <SliderInput
        minWidth={220}
        label="Uncertain Hours"
        value={timesheetFilterState?.uncertainHours}
        onChange={handleFilterChange("uncertainHours")}
        min={0}
        max={100000}
      />
      <SliderInput
        minWidth={220}
        label="Reconciled Hours"
        value={timesheetFilterState?.reconciledHours}
        onChange={handleFilterChange("reconciledHours")}
        min={0}
        max={100000}
      />
    </Box>
  );
}

export default TimesheetFilters;
