import axios from "axios";
import React, { createContext, useState } from "react";
import { BaseURL } from "../constants/Baseurl";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [projects, setProjects] = useState([]);
  const [projectFilterState, setProjectFilterState] = useState({
    companyId: [],
    portfolioId: [],
    accountingYear: [],
    accYear: "",
    company: "",
    project: "",
  });
  const [clearProjectFilterTrigger, setClearProjectFilterTrigger] =
    useState(false);
  const [isProjectFilterApplied, setIsProjectFilterApplied] = useState(false);
  const [currentState, setCurrentState] = useState(
    pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
  );
  const [timesheetProject, setTimesheetProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();

  const triggerProjectClearFilters = () => {
    setClearProjectFilterTrigger((prev) => !prev);
  };

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  const applySort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/get-company-list`;
    try {
      toast.loading("Fetching client data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url,config)
      toast.dismiss();
      console.log("response for fetching clients :", response?.data?.data);
      setProjects(response?.data?.data);
      toast.success( response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching client data");
      console.error("Error in fetching client data : ",error);
    }
  }

  const fetchProjects = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (options.companyId)
      queryParams.append("companyIds", JSON.stringify(options.companyId));
    if (options.timesheetId)
      queryParams.append("timesheetId", JSON.stringify(options.timesheetId));
    if (options.portfolioId)
      queryParams.append("portfolioIds", JSON.stringify(options.portfolioId));
    if (options.accountingYear)
      queryParams.append(
        "accountingYears",
        JSON.stringify(options.accountingYear)
      );
    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects${queryString ? `?${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);
    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data);
      if (options?.timesheetId) {
        setTimesheetProject(response?.data?.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        timesheetProject,
        fetchProjects,
        applySort,
        projectFilterState,
        setProjectFilterState,
        triggerProjectClearFilters,
        clearProjectFilterTrigger,
        isProjectFilterApplied,
        setIsProjectFilterApplied,
        setCurrentState,
        currentState,
        loading
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
