import React, { useContext, useEffect, useState } from "react";
import CompanySelector from "../FilterComponents/CompanySelector";
import ProjectSelector from "../FilterComponents/ProjectSelector";
import DocumentTypeSelector from "../FilterComponents/DocumentTypeSelector";
// import UploadOnSelector from "../FilterComponents/UploadOnSelector";
// import UploadBySelector from "../FilterComponents/UploadedBySelector";
import { Box } from "@mui/material";
import axios from "axios";
import { BaseURL } from "../../constants/Baseurl";
import { DocumentContext } from "../../context/DocumentContext";
import { Authorization_header } from "../../utils/helper/Constant";

function DocumentFilters({ documentClientData, documentType }) {
  const { docFilterState, setDocFilterState, clearFilterTrigger } =
    useContext(DocumentContext);
  const [company, setCompany] = useState(docFilterState.company);
  const [project, setProject] = useState(docFilterState.project);
  const [doc, setDoc] = useState(docFilterState.document);
  const [companyProjects, setCompanyProjects] = useState(null);

  useEffect(() => {
    setDocFilterState({
      ...docFilterState,
      companyId: [
        documentClientData?.find((client) => client?.companyName === company)
          ?.companyId,
      ],
      projectId: companyProjects?.find((proj) => proj?.projectName === project)
        ?.projectId,
      document: doc,
      company: company,
      project: project,
    });
  }, [company, project, doc]);

  const fetchData = async () => {
    try {
      if (docFilterState?.companyId) {
        const response3 = await axios.get(
          `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/${docFilterState?.companyId
          }/get-projects-by-company`, Authorization_header()
        );
        setCompanyProjects(response3.data.data);
      } else {
        console.error("companyId not available in data object");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [docFilterState?.companyId]);

  useEffect(() => {
    if (clearFilterTrigger) {
      setCompany("");
      setProject("");
      setDoc("");
    }
  }, [clearFilterTrigger]);

  return (
    <Box>
      <CompanySelector
        clients={documentClientData}
        company={company}
        setCompany={setCompany}
      />
      <ProjectSelector
        companyProjects={companyProjects}
        project={project}
        setProject={setProject}
      />
      <DocumentTypeSelector docType={documentType} doc={doc} setDoc={setDoc} />
      {/* <UploadOnSelector />
      <UploadBySelector /> */}
    </Box>
  );
}

export default DocumentFilters;
