import React from "react";
import { Link } from "react-router-dom";
import { TableBody, TableRow, TableCell } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormatDatetime from "../../utils/helper/FormatDatetime";

const cellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  textAlign: "center",
  py: 1.5,
  fontSize: "12px",
};
const currencyCellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  textAlign: "right",
  py: 1.5,
  fontSize: "12px",
};


const cellLinkStyle = {
  ...cellStyle,
  color: "#00A398",
  textDecoration: "underline",
  cursor: "pointer",
};

function TeamTableBody({ filledRows, rowsPerPage, symbol }) {
  // const rows = Array.from(
  //   { length: rowsPerPage || 10 },
  //   (_, index) => filledRows?.[index] || null
  // );

  return (
    <>
      <TableBody>
        {filledRows?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell sx={{ ...cellStyle, textAlign: "left" }}>
              {row?.contactFirstName && row?.contactFirstName + " " + row?.contactLastName}
            </TableCell>
            <TableCell sx={cellStyle}>{row?.contactTitle || ""}</TableCell>
            <TableCell sx={currencyCellStyle}>{`${symbol}${row?.hourlyRate?.toLocaleString('en-US') || ""} `}</TableCell>
            <TableCell sx={cellStyle}>{row?.totalHours?.toLocaleString('en-US') || ""}</TableCell>
            <TableCell sx={cellStyle}>{row?.rndHours?.toLocaleString('en-US') || ""}</TableCell>
            <TableCell sx={currencyCellStyle}>{`${symbol}${row?.rndCost?.toLocaleString('en-US') || ""}`}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export default TeamTableBody;
