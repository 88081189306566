import axios from "axios";
import React, { createContext, useState } from "react";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const TimesheetContext = createContext();

export const TimesheetProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetFilterState, setTimesheetFilterState] = useState({
    companyId: [],
    company: "",
    monthName: "",
    month: [],
    nonRnDHours: [0, 5000],
    rnDHours: [0, 5000],
    uncertainHours: [0, 5000],
    reconciledHours: [0, 5000],
  });
  const [clearTimesheetFilterTrigger, setClearTimesheetFilterTrigger] =
    useState(false);
  const [isTimesheetFilterApplied, setIsTimesheetFilterApplied] =
    useState(false);
  const [currentState, setCurrentState] = useState(
    pinnedObject?.TIMESHEETS === "RV" ? "Recently Viewed" : "All Timesheets"
  );
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();

  const triggerTimesheetClearFilters = () => {
    setClearTimesheetFilterTrigger((prev) => !prev);
  };

  const fetchTimesheets = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (options.client)
      queryParams.append("client", JSON.stringify(options.client));
    if (options.month)
      queryParams.append("month", JSON.stringify(options.month));
    if (options.nonRnDHoursMin)
      queryParams.append("nonRnDHoursMin", options.nonRnDHoursMin);
    if (options.nonRnDHoursMax)
      queryParams.append("nonRnDHoursMax", options.nonRnDHoursMax);
    if (options.rnDHoursMin)
      queryParams.append("rnDHoursMin", options.rnDHoursMin);
    if (options.rnDHoursMax)
      queryParams.append("rnDHoursMax", options.rnDHoursMax);
    if (options.uncertainHoursMin)
      queryParams.append("uncertainHoursMin", options.uncertainHoursMin);
    if (options.uncertainHoursMax)
      queryParams.append("uncertainHoursMax", options.uncertainHoursMax);
    if (options.reconciledHoursMin)
      queryParams.append("reconciledHoursMin", options.reconciledHoursMin);
    if (options.reconciledHoursMax)
      queryParams.append("reconciledHoursMax", options.reconciledHoursMax);
    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/timesheets/${localStorage.getItem(
      "userid"
    )}/1/timesheet-logs${queryString ? `?${queryString}` : ""}`;
    setIsTimesheetFilterApplied(queryString);

    try {
      const response = await axios.get(url, Authorization_header());
      setTimesheets(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <TimesheetContext.Provider
      value={{
        timesheetFilterState,
        setTimesheetFilterState,
        timesheets,
        fetchTimesheets,
        triggerTimesheetClearFilters,
        clearTimesheetFilterTrigger,
        isTimesheetFilterApplied,
        setIsTimesheetFilterApplied,
        currentState,
        setCurrentState,
        loading
      }}
    >
      {children}
    </TimesheetContext.Provider>
  );
};
