import React, { useState } from "react";
import { GoDownload, GoUpload } from "react-icons/go";
import { Typography, Box, Button } from "@mui/material";
// import ConstructionIcon from "@mui/icons-material/Construction";
import ModalForm from "./ModalForm";
import { BaseURL } from "../../constants/Baseurl";
import axios from "axios";
import { Authorization_header, token_obj } from "../../utils/helper/Constant";
import toast from "react-hot-toast";

const styles = {
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  paddingLeftBox: {
    p: 1,
  },
  companyTypography: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
  },
  appleSpan: {
    fontSize: "15px",
    color: "#00A398",
  },
  appleIncTypography: {
    display: "flex",
    alignItems: "center",
    fontSize: "25px",
    fontWeight: 600,
  },
  lanIcon: {
    borderRadius: "50%",
    border: "1px solid black",
    padding: "5px",
    fontSize: "30px",
    ml: 2,
    "&:hover": {
      color: "#FD5707",
      border: "1px solid #FD5707",
    },
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    mt: -3,
    p: 1,
  },
  buttonStyle: {
    textTransform: "capitalize",
    borderRadius: "20px",
    backgroundColor: "#00A398",
    mr: 2,
    "&:hover": {
      backgroundColor: "#00A398",
    },
  },
  goDownloadIcon: {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "33px",
    padding: "5px",
    marginRight: "16px",
  },
};

function TimesheetInfoboxHeader({
  head,
  data,
  downloadPermission = true,
  uploadPermission = true,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (formData) => {
    const apiUrl = `${BaseURL}/api/v1/timesheets/${localStorage.getItem(
      "userid"
    )}/1/${data?.timesheetId}/timesheet-reupload`;
    const dataToBeSent = {
      companyId: data?.companyId,
      timesheet: formData.file,
    };

    try {
      const response = await axios.post(apiUrl, dataToBeSent, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj.accessToken}`
        },
      });


      handleModalClose();
    } catch (error) {
      console.error("Error uploading timesheet:", error);

      // Revert optimistic update in case of an error
      // setTsData((prevData) =>
      //   prevData.filter((item) => item.id !== optimisticData.id)
      // );
    }
  };

  const handleTriggerAi = async () => {
    setLoading(true);
    loading && toast.loading("Triggering AI");
    try {
      let api;
      if (true) {
        api = `${BaseURL}/api/v1/timesheets/${data?.timesheetId}/trigger-ai`;
      }
      const response = await axios.post(api,{}, Authorization_header());
      response && setLoading(false);
      !loading && toast.success(response?.data?.message);
    } catch (err) {
      console.error(JSON.stringify(err));
      toast.error("Error in Triggering AI");
    }
  }


  return (
    <>
      <Box sx={styles.flexBox}>
        <Box sx={styles.paddingLeftBox}>
          <Typography sx={styles.companyTypography}>
            Timesheets &gt;
            <span style={styles.appleSpan}>{head}</span>
          </Typography>
          <Typography sx={styles.appleIncTypography}>{head}</Typography>
        </Box>
        <Box sx={styles.buttonGroup}>

          {uploadPermission && (
            <>
              <Button
                variant="contained"
                sx={styles.buttonStyle}
                onClick={openModal}
              >
                <GoUpload style={{ fontSize: "20px" }} /> Reupload
              </Button>
              <Button
                variant="contained"
                sx={styles.buttonStyle}
                onClick={() => {
                  handleTriggerAi();
                }}
              >
                Trigger AI
              </Button>
            </>
          )}
          <ModalForm
            open={isModalOpen}
            handleClose={handleModalClose}
            type={"reupload"}
            data={data}
            handleSubmit={handleFormSubmit}
          />
          {/* {downloadPermission && <GoDownload style={styles.goDownloadIcon} />} */}
        </Box>
      </Box>
    </>
  );
}

export default TimesheetInfoboxHeader;
