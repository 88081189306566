import React, { createContext, useEffect, useState } from 'react';
import { BaseURL } from '../constants/Baseurl';
import axios from 'axios';
import { Authorization_header } from '../utils/helper/Constant';

export const EmployeeContext = createContext();

export const EmployeesProvider = ({ children }) => {
    const [employeesSheets, setEmployeesSheets] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchEmployeesSheets = async (options) => {
        setLoading(true);
        const url = `${BaseURL}/api/v1/contacts/get-employee-sheets`;
        try {
            const response = await axios.get(url, Authorization_header());
            setEmployeesSheets(response?.data?.data)
            setLoading(false);

        } catch (err) {
            console.error(err);
        }
    };


    return (
        <EmployeeContext.Provider
            value={{
                fetchEmployeesSheets,
                employeesSheets
            }}
        >{children}</EmployeeContext.Provider>
    )
}