import React from "react";
import FilterCustomAutocomplete from "../Common/FilterCustomAutocomplete";

function DocumentTypeSelector({ docType, doc, setDoc, disabled, error }) {
  return (
    <>
      <FilterCustomAutocomplete
        label=""
        placeholder="Select Document Type"
        options={docType?.map((doc) => doc) ?? []}
        value={doc}
        onChange={(event, newValue) => {
          setDoc(newValue ?? "");
        }}
        inputValue={doc}
        onInputChange={(event, newInputValue) => {
          setDoc(newInputValue ?? "");
        }}
        disabled={disabled}
        heading= {"Document Type"}
      />
    </>
  );
}

export default DocumentTypeSelector;
