import React, { useEffect, useState } from "react";
import { Box, Button, Table, TableContainer, TablePagination } from "@mui/material";
import UpdationDetails2 from "../Common/UpdationDetails2";
import AddIcon from "@mui/icons-material/Add";
import MiniTableHeader from "../Common/MiniTableHeader";
import TeamTableBody from "./TeamTableBody";
import TeamModal from "./Team/TeamModal";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";

const tableData = {
  columns: [
    "Name",
    "Role",
    "Hourly Rate",
    "Total Hours",
    "R&D Hours",
    "R&D Expense",
  ],
  rows: [
    {
      id: 1,
      name: "Adam Smith",
      title: "Finance Head",
      role: "Finance Head",
      company: "Apple Inc.",
      status: "Active",
      startDate: "01/01/2023",
      endDate: "-",
      phone: "(336)-222-7000",
      email: "adamsmith@apple.com",
    },
  ],
};

function Team({
  data,
  latestUpdateTime,
  modifiedBy,
  fetchTeamMembers,
  details,
  symbol
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredProjectTeam, setFilteredProjectTeam] = useState([]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (data) {
      const filteredData = data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      setFilteredProjectTeam(filteredData);
    }
  }, [data, page, rowsPerPage]);

  return (
    <>
    
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />
          {useHasAccessToFeature("F017", "P000000007") && (
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                backgroundColor: "#00A398",
                color: "white",
                mr: 2,
                "&:hover": {
                  backgroundColor: "#00A398",
                },
              }}
              onClick={() => setModalOpen(!modalOpen)}
            >
              <AddIcon style={{ fontSize: "17px", marginRight: "3px" }} />
              New Team Member
            </Button>
          )}
          <TeamModal
            fetchTeamMembers={fetchTeamMembers}
            open={modalOpen}
            handleClose={handleModalClose}
            details={details}
          />
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            // maxHeight: "50vh",
            height: 300
          }}
        >
          <Table stickyHeader aria-label="simple table">
            <MiniTableHeader tableData={tableData} />
            <TeamTableBody filledRows={filteredProjectTeam} rowsPerPage={rowsPerPage} symbol={symbol} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

export default Team;
