import { CircularProgress, Paper, Table, TableContainer } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomPagination from "../../components/Common/CustomPagination";
import TableHeader from "../../components/Common/TableHeader";
import TableIntro from "../../components/Common/TableIntro";
import CasesTableBody from "../../components/Cases/CasesTableBody";
import toast, { Toaster } from "react-hot-toast";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import { FilterListContext } from "../../context/FiltersListContext";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import CaseModalForm from "../../components/Cases/CaseModalForm";
import { CaseContext } from "../../context/CaseContext";
import { Authorization_header } from "../../utils/helper/Constant";

const tableData = {
  columns: [
    "Case Code",
    "Case type",
    "Account",
    "Location",
    "Case Owner",
    "Created On",
  ],
};

function Cases() {
  const { pinnedObject } = usePinnedData();
  const {
    getAllCases,
    caseData,
    loading
  } = useContext(CaseContext);
  const { clientList, } = useContext(FilterListContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [pinStates, setPinStates] = useState({
    "All Cases": false,
    "Recently Viewed": false,
  });

  const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  const currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const placeholderRow = {};
  while (currentData?.length < itemsPerPage) {
    currentData.push(placeholderRow);
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getAllCases();
  }, [Authorization_header]);



  useEffect(() => {
    const updatedPinStates = {
      "All Cases": pinnedObject.CASES === "ALL",
      "Recently Viewed": pinnedObject.CASES === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.CASES]);


  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (caseData) {
      const filteredData = caseData?.filter(
        (task) =>
          task?.caseCode?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.primaryContactName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.companyName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.primaryContactEmail?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.caseOwnerName?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [caseData, search]);

  const isSearch = useHasAccessToFeature("F018", "P000000009");

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Cases"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Cases"] = true;
      }

      return newState;
    });
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Cases" ? "ALL" : "RV";
    }
  }, [pinStates]);

  return (
    <>
      {useHasAccessToFeature("F018", "P000000008") && (
        <Paper
          sx={{
            display: "flex",
            width: "98.5%",
            mx: "auto",
            mt: 1,
            flexDirection: "column",
            borderRadius: "20px",
            mb: 3,
            boxShadow: "0px 3px 6px #0000001F",
          }}>
          <TableIntro
            heading={
              pinnedObject?.CASES === "RV"
                ? "Recently Viewed"
                : "All Cases"
            }
            btnName={"Add Case"}
            page={"case"}
            totalItems={filteredRows?.length || 0}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onUploadClick={handleUploadClick}
            onSearch={handleSearch}
            latestUpdateTime={latestUpdateTime}
            items={["All Cases", "Recently Viewed"]}
            searchPermission={isSearch}
          />
          <CaseModalForm
            open={modalOpen}
            handleClose={handleModalClose}
            clients={clientList}
            handleFetchAllCases={getAllCases}
          />
          <TableContainer sx={{
            maxHeight: "82vh",
            overflowY: "auto",
          }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHeader tableData={tableData} />
              <CasesTableBody
                data={currentData}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              />
            </Table>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                <CircularProgress sx={{ color: "#00A398" }} />
              </div>
            )}
          </TableContainer>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            changePage={handleChangePage}
            changeItemsPerPage={handleChangeItemsPerPage}
            minRows={20}
          />
        </Paper>
      )}
      <Toaster />
    </>
  );
}

export default Cases;
