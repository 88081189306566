import { CircularProgress, Table, TableContainer, TablePagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../context/ClientContext";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import CustomPagination from "../Common/CustomPagination";
import TableHeader from "../Common/TableHeader";
import TableIntro from "../Common/TableIntro";
import usePinnedData from "../CustomHooks/usePinnedData";
import CompanyModal from "./CompanyModal";
import CompanyTableBody from "./CompanyTableBody";
import { BaseURL } from "../../constants/Baseurl";
import axios from "axios";
import { FilterListContext } from "../../context/FiltersListContext";
import { token_obj } from "../../utils/helper/Constant";

const tableData = {
  columns: [
    "Account Name",
    "Account ID",
    "Total Projects",
    "Billing Country",
    " Auto Send Interaction",
    "Total Expense",
    "Total R&D Expense",
    "Primary Contact",
    "Phone",
    "Email Address",
  ],
  rows: [
    {
      id: 1,
      companyName: "Apple Inc.",
      projects: 12,
      companySite: "California",
      billingState: "California",
      type: "Parent",
      spoc: "Adam Smith",
      phone: "(336) 222-7000",
      mail: "adam.smith@apple.com",
    },
    {
      id: 2,
      companyName: "Burlington Textiles Corp of America",
      projects: 8,
      companySite: "Texas",
      billingState: "Texas",
      type: "Direct",
      spoc: "Trisha Col",
      phone: "(632) 782-2619",
      mail: "col.trisha@btca.com",
    },
  ],
};

function CompanyTableStack({
  data,
  latestUpdateTime,
  getData,
  onApplyFilters,
  appliedFilters,
  loading
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const { currentState, setCurrentState } = useContext(ClientContext);
  const { pinnedObject } = usePinnedData();
  const [pinStates, setPinStates] = useState({
    "All Accounts": false,
    "Recently Viewed": false,
  });
  const { fetchUserDetails } = useContext(FilterListContext);

  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
  };
  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  while (currentData?.length < itemsPerPage) {
    currentData?.push({});
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (data) {

      const filteredData = data?.filter(
        (task) => {
          const id = task.companyIdentifier;
          return task?.companyName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.companyId?.toLowerCase()?.includes(search?.toLowerCase()) ||
            (task?.companyIdentifier + "")?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.companyType?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.primaryContact
              ?.toLowerCase()
              ?.includes(search?.toLowerCase()) ||
            task?.phone?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.billingCity?.toLowerCase()?.includes(search?.toLowerCase())
        }
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [data, search]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  useEffect(() => {
    const updatedPinStates = {
      "All Accounts": (!pinnedObject || !pinnedObject.CLNT || pinnedObject.CLNT === "ALL"),
      "Recently Viewed": pinnedObject.CLNT === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.CLNT]);

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse = !newState["All Accounts"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Accounts"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      CLNT: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token_obj.accessToken}` },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);

      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Accounts" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  return (
    <>
      <TableIntro
        heading={
          (!pinnedObject || !pinnedObject?.CLNT || pinnedObject?.CLNT?.toUpperCase() === "ALL") ? "All Accounts" : "Recently Viewed"
        }
        btnName={"New Account"}
        page={"company"}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredRows?.length || 0}
        onUploadClick={handleUploadClick}
        onSearch={handleSearch}
        latestUpdateTime={latestUpdateTime}
        items={["All Accounts", "Recently Viewed"]}
        onApplyFilters={onApplyFilters}
        appliedFilters={appliedFilters}
        createPermission={useHasAccessToFeature("F005", "P000000007")}
        searchPermission={useHasAccessToFeature("F005", "P000000009")}
        onSelectedItem={handleSelectedHeaderItem}
        isPinnedState={pinStates[currentState]}
        onPinClicked={() => togglePinState(currentState)}
      />
      <CompanyModal
        open={modalOpen}
        handleClose={handleModalClose}
        getData={getData}
      />
      <TableContainer
        sx={{
          maxHeight: "82vh",  // Set the desired height for scrollable area
          overflowY: "auto",   // Enable vertical scrolling
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader tableData={tableData} />
          {!loading && <CompanyTableBody
            filledRows={currentData}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
          }
        </Table>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              minHeight: "380px",
            }}
          >
            <CircularProgress sx={{ color: "#00A398" }} />
          </div>
        )}
        {currentData.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              minHeight: "380px",
            }}
          >
            No Account found.
          </div>
        )}
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={itemsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        changePage={handleChangePage}
        changeItemsPerPage={handleChangeItemsPerPage}
      />
    </>
  );
}

export default CompanyTableStack;


















