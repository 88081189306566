import React, { useEffect } from "react";
import { Box, Table, TableContainer } from "@mui/material";
import UpdationDetails2 from "../Common/UpdationDetails2";
import MiniTableHeader from "../Common/MiniTableHeader";
import { HiFilter } from "react-icons/hi";
import ContactsRndTableBody from "./ContactsRndTableBody";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import ContactsSalaryTableBody from "./ContactsSalaryTableBody";

const tableData = {
    columns: [
        "Annual Salary",
        "Hourly Rate",
        "Start Date",
        "End Date",

    ],
    rows: [
        {
            id: 1,
            projectId: "",
            timesheet: "",
            month: "",
            rndHours: "",
            hourlyRate: "",
            rndExpense: "",
        },
    ],
};

const filterIcon = {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "32px",
    padding: "5px",
    marginRight: "16px",
};

const SalaryTab = ({ data, modifiedBy, latestUpdateTime, currency, currencySymbol }) => {
    return (
        <>
            <Box
                sx={{
                    borderTop: "1px solid #E4E4E4",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <UpdationDetails2
                        items={data?.length}
                        latestUpdateTime={latestUpdateTime}
                        modifiedBy={modifiedBy}
                    />
                </Box>
            </Box>
            <Box>
                <TableContainer
                    sx={{
                        width: "100%",
                        maxHeight: "50vh",
                        overflowX: "auto",
                    }}
                >
                    <Table stickyHeader aria-label="simple table">
                        <MiniTableHeader tableData={tableData} />
                        <ContactsSalaryTableBody filledRows={data}
                            currency={currency}
                            currencySymbol={currencySymbol}
                        />
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};

export default SalaryTab;
