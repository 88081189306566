import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import ContactTableCell from "../Common/ContactTableCell";
import CompanyTableCell from "../Common/CompanyTableCell";

const styles = {
  boxStyle: {
    p: 1,
    borderTop: "1px solid #E4E4E4",
  },
  tableStyle: {
    minWidth: 650,
  },
  tableHeadCell: {
    border: "none",
    paddingBottom: 0,
    fontWeight: 600,
    fontSize: "13px",
    whiteSpace: "nowrap",
  },
  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
  },
  tableCell: {
    fontSize: "13px",
    whiteSpace: "nowrap",
  },
  iconStyle: {
    backgroundColor: "#FD5707",
    borderRadius: "50%",
    color: "white",
    fontSize: "13px",
    ml: 1,
  },
  cellLinkStyle: {
    fontSize: "13px",
    whiteSpace: "nowrap",
    color: "#00A398",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

function ProjectsInfoboxTable({ info, onSelectedChange, symbol }) {
  const handleClick = (tab) => {
    onSelectedChange(tab);
  };

  const formatCurrency = (amount, locale = 'en-US', currencySymbol = 'USD') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencySymbol,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <>
      <Box sx={styles.boxStyle}>
        <TableContainer>
          <Table sx={styles.tableStyle} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeadCell}>
                  Total hours
                </TableCell>
                <TableCell sx={styles.tableHeadCell}>
                  R&D Potential(%)
                </TableCell>
                <TableCell sx={styles.tableHeadCell}>
                  Total Cost
                </TableCell>
                <TableCell sx={styles.tableHeadCell}>
                  R&D Expense
                </TableCell>
                {/* {useHasAccessToFeature("F018", "P000000003") ? (
                  <TableCell
                    sx={{
                      ...styles.tableHeadCell,
                      color: "#FD5707",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClick("Timesheets")}
                  >
                    Uncertain Hours <ChevronRightIcon sx={styles.iconStyle} />
                  </TableCell>
                ) : (
                  <TableCell sx={styles.tableHeadCell}>
                    Uncertain Hours
                  </TableCell>
                )} */}
                <TableCell sx={styles.tableHeadCell}>Accounts</TableCell>
                <TableCell sx={styles.tableHeadCell}>SPOC Name</TableCell>
                <TableCell sx={styles.tableHeadCell}>Fiscal Year</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.tableCell}>
                  {`${info?.totalEfforts?.toLocaleString('en-US') || ""}`}
                </TableCell>
                <TableCell sx={{ ...styles.tableCell, color: "#FD5707" }}>
                  {info?.rndPotential?.toFixed(2).toLocaleString('en-US') || ""}
                </TableCell>
                <TableCell sx={{ ...styles.tableCell, color: "#FD5707" }}>
                  {info?.totalCosts ? formatCurrency(info?.totalCosts, 'en-US') : ""}
                </TableCell>
                <TableCell sx={{ ...styles.tableCell, color: "#FD5707" }}>
                  {info?.rndExpense ? formatCurrency(info?.rndExpense, 'en-US') : ""}
                </TableCell>
                <CompanyTableCell id={info?.companyId} name={info?.companyName} />
                <TableCell sx={styles.tableCell}>
                  {info?.spocName || ""}
                </TableCell>
                <TableCell sx={{ ...styles.tableCell, color: "#FD5707" }}>
                  {info?.accountingYear ? `FY ${+(info?.accountingYear) - 1}-${info?.accountingYear.slice(-2)}` : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ProjectsInfoboxTable;
