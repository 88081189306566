import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ContactContext } from "../../context/ContactContext";
import { FilterListContext } from "../../context/FiltersListContext";
import CompanySelector from "../FilterComponents/CompanySelector";
import TitleSelector from "../FilterComponents/TitleSelector";
import { titles } from "../../constants/Titles";

function ContactFilters() {
  const {
    contactFilterState,
    setContactFilterState,
    clearContactFilterCounter
  } = useContext(ContactContext);
  const { clientList } = useContext(FilterListContext);
  const [company, setCompany] = useState(contactFilterState.company);
  const [title, setTitle] = useState(contactFilterState.titleName);

  useEffect(() => {
    setContactFilterState({
      ...contactFilterState,
      companyId: [
        clientList?.find((client) => client?.companyName === company)
          ?.companyId,
      ],
      company: company,
    });
  }, [company]);

  useEffect(() => {
    setContactFilterState({
      ...contactFilterState,
      title: [title],
      titleName: title,
    });
  }, [title]);

  // useEffect(() => {
  //   setCompany("");
  //   setTitle("");
  // }, [clearContactFilterCounter]);

  return (
    <Box>
      <CompanySelector
        clients={clientList}
        company={company}
        setCompany={setCompany}
      />
      <TitleSelector titles={titles} title={title} setTitle={setTitle} />
    </Box>
  );
}

export default ContactFilters;
