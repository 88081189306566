import { Box, InputLabel } from "@mui/material";
import React from "react";
import CustomAutocomplete from "../../Common/CustomAutocomplete";

const CountrySelect = ({ cntry, country, setCountry }) => {
  return (
    <Box>
      <InputLabel sx={{ color: "#404040", fontSize: "14px", fontWeight: 400 }}>
        Country
      </InputLabel>
      <CustomAutocomplete
        label=""
        placeholder="Select Country"
        options={cntry?.map((ctry) => ctry) ?? []}
        value={country}
        onChange={(event, newValue) => {
          setCountry(newValue ?? "");
        }}
        inputValue={country}
        onInputChange={(event, newInputValue) => {
          setCountry(newInputValue ?? "");
        }}
      />
    </Box>
  );
};

export default CountrySelect;
