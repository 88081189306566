export const formatFilters = (filters) => {
  if (!filters) return "";

  return Object?.entries(filters)
    ?.filter(([key, value]) => value !== undefined && value !== "")
    ?.map(([key, value]) => {
      const friendlyKey = key?.replace(/([A-Z])/g, " $1")?.trim();
      return `${friendlyKey} (${value})`;
    })
    ?.join(", ");
};
