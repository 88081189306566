import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Table, TableContainer, TablePagination } from "@mui/material";
import MiniTableHeader from "../Common/MiniTableHeader";
import TaskTableBody from "./TaskTableBody";
import axios from "axios";
import { BaseURL } from "../../constants/Baseurl";
import { Authorization_header } from "../../utils/helper/Constant";

const tableData = {
    columns: [
        "Task Date",
        "Project ID",
        "Task Description",
        "Hourly Rate",
        "Task Effort(Hrs)",
        "Total Expense",
        "R&D Expense",
        "Created By",
        "Created Time",
        "Task ID",
    ],
    rows: [
        {
            id: 1,
            name: "Adam Smith",
            title: "Finance Head",
            role: "Finance Head",
            company: "Apple Inc.",
            status: "Active",
            startDate: "01/01/2023",
            endDate: "-",
            phone: "(336)-222-7000",
            email: "adamsmith@apple.com",
        },
    ],
};

function Task({
    details,
    symbol
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredProjectTask, setFilteredProjectTask] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [error, setError] = useState(null);
    const [totalTask, setTotalTask] = useState(0);
    const [loading, setLoading] = useState(false);

    const projectTask = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${BaseURL}/api/v1/projects/${localStorage.getItem(
                    "userid"
                )}/${details?.id}/tasks?page=${page}&limit=${rowsPerPage}`, Authorization_header()
            );
            setTaskData(response?.data?.data?.tasks || []);
            setTotalTask(response?.data?.data?.taskCount)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError("An error occurred");
        }
    };

    useEffect(() => {
        projectTask();
    }, [details?.id, page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <>
            <Box
                sx={{
                    borderTop: "1px solid #E4E4E4",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
            </Box>
            <Box>
                <TableContainer
                    sx={{
                        width: "100%",
                        overflowX: "auto",
                        // maxHeight: "50vh",
                        height: 300
                    }}
                >
                    <Table stickyHeader aria-label="simple table">
                        <MiniTableHeader tableData={tableData} />
                        {!loading && <TaskTableBody filteredProjectTask={taskData} rowsPerPage={rowsPerPage} symbol={symbol} />}
                    </Table>
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "50px",
                                minHeight: "380px",
                            }}
                        >
                            <CircularProgress sx={{ color: "#00A398" }} />
                        </div>
                    )}
                    {taskData.length === 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "50px",
                                minHeight: "380px",
                            }}
                        >
                            No task found.
                        </div>
                    )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={totalTask}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </>
    );
}

export default Task;
