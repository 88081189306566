import React from "react";
import FilterCustomAutocomplete from "../Common/FilterCustomAutocomplete";

function CompanySelector({ clients, company, setCompany }) {
  return (
    <>
      <FilterCustomAutocomplete
        label=""
        placeholder="Select Account"
        options={clients?.map((client) => client?.companyName) ?? []}
        value={company}
        onChange={(newValue) => {
          setCompany(newValue ?? "");
        }}
        inputValue={company}
        onInputChange={(newInputValue) => {
          setCompany(newInputValue ?? "");
        }}
        heading={"Account"}
      />
    </>
  );
}

export default CompanySelector;
