import { TableCell, Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { postRecentlyViewed } from "../../utils/helper/PostRecentlyViewed";

const cellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  textAlign: "center",
  fontSize: "13px",
  py: 1,
};

const cellLinkStyle = {
  ...cellStyle,
  color: "#00A398",
  textDecoration: "underline",
  cursor: "pointer",
  textAlign: "left",
};

const ProjectTableCell = ({ id, name, tabName="" }) => {
  const navigate = useNavigate();

  const handleClick = (e, id) => {
    e.stopPropagation();
    (async () => {
      await postRecentlyViewed(id, "projects");
      navigate(`/projects/info?projectId=${encodeURIComponent(id)}&tabName=${tabName}`);
    })();
  };

  const hasAccess = useHasAccessToFeature("F013", "P000000004");

  return (
    <TableCell sx={hasAccess ? cellLinkStyle : cellStyle}>
      {hasAccess ? (
        <button
          style={{ all: "unset", cursor: "pointer" }}
          onClick={(e) => handleClick(e, id)}
        >
          <Tooltip title={name || ""}>
            <span>
              {name?.length > 15 ? `${name?.substring(0, 15)}...` : name || ""}
            </span>
          </Tooltip>
        </button>
      ) : (
        name || ""
      )}
    </TableCell>
  );
};

export default React.memo(ProjectTableCell);

