import { Box, Button, CircularProgress, InputLabel, Table, TableContainer, TablePagination, Snackbar, Alert, Tooltip, InputBase, InputAdornment } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";
// import { Switch } from '@mui/material';
import MiniTableHeader from '../../Common/MiniTableHeader';
import CaseInteractionListingData from './CaseInteractionListingData';
import { BaseURL } from '../../../constants/Baseurl';
import axios from 'axios';
import { CaseContext } from '../../../context/CaseContext';
import NewInteractionModal from '../../Common/NewInteractionModal';
import toast from 'react-hot-toast';
import { Authorization_header } from '../../../utils/helper/Constant';
import SpocIncludeProjectsModal from '../SpocIncludeProjectsModal';

const columns = [
    "Interaction ID",
    "Interaction History",
    "Project ID",
    "Project Name",
    "Status",
    "Sent Date",
    "Response Date",
    "Sent To",
    "External Link"
]

const styles = {
    inputBase: {
        borderRadius: "20px",
        height: "40px",
        pl: 1,
        width: "200px",
        border: "1px solid #E4E4E4",
    },
    label: {
        color: "#404040",
        fontSize: "14px",
        fontWeight: 600,
    },
    searchIconStyle: {
        color: "#9F9F9F",
        ml: "3px",
        mr: "-3px",
        width: "20px",
        height: "20px",
    },
    uploadButtonStyle: {
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#00A398",
        "&:hover": { backgroundColor: "#00A398" },
    },
    buttonStyle: {
        mr: 1,
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#9F9F9F",
        "&:hover": { backgroundColor: "#9F9F9F" },
    }, iconStyle: { fontSize: "20px", color: "#9F9F9F" },

    searchIconStyle: {
        color: "#9F9F9F",
        ml: "3px",
        mr: "-3px",
        width: "20px",
        height: "20px",
    },
}


const CaseInteractionListing = ({ handleShowInteractionListing, handleInteractionId, usedfor, caseId, projectId }) => {
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [interactionData, setInteractionData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showSendInteractions, setShowSendInteractions] = useState(false);
    const [interactionIds, setInteractionIds] = useState([]);
    const [handleConfirmationModalOpen, setHandleConfirmationModalOpen] = useState(false);
    const [interactionPurpose, setInteractionPurpose] = useState(null);
    const [showUpdateSpocModal, setShowUpdateSpocModal] = useState(false);
    const UpdatePurposeRef = useRef();
    UpdatePurposeRef.current = "Interactions"

    function capitalizeFirstLetter(str) {
        if (!str) return str; // Check if the string is empty or not
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }


    const handleSendMail = async ({ interactionIds, sendInteraction }) => {
        toast.loading(`${interactionPurpose} sending...`);
        const querryData = { interactionIds: [...interactionIds] };
        if (interactionPurpose === "interactions") {
            querryData.sendInteraction = true;
        } else if (interactionPurpose === "reminder") {
            querryData.sendReminder = true;
        }
        try {
            const res = await axios.post(`${BaseURL}/api/v1/assessment/${localStorage.getItem(
                "userid"
            )}/send-interactions`, querryData, Authorization_header());
            toast.dismiss();
            toast.success(`${capitalizeFirstLetter(interactionPurpose)} sent successfully...`);

            getAllInteractions();
        } catch (error) {
            toast.dismiss();
            toast.error(error?.response?.data?.message || `Failed to send ${capitalizeFirstLetter(interactionPurpose)}. Server error`);
            console.error(error);
        }
    }

    const handleOpen = () => {
        setShowUpdateSpocModal(true);
    };

    const handleClose = () => {
        setShowUpdateSpocModal(false);
    };

    const filteredColumns = (usedfor === 'project') ? columns.filter(column => column != "Interaction History") : columns;
    const tableData = {
        columns: filteredColumns
    }

    const handleSendInteractionsClick = (val) => {
        setInteractionIds([]);
        if (interactionPurpose == 'interactions') {
            setShowSendInteractions(true);
        }
        setInteractionPurpose("interactions");
    }

    const handleSendReminderClick = () => {
        setInteractionIds([]);
        if (interactionPurpose == 'reminder') {
            setShowSendInteractions(true);
        }
        setInteractionPurpose("reminder");
    }

    useEffect(() => {
        if (interactionPurpose) {
            setShowSendInteractions(true);
        }
    }, [interactionPurpose])


    const handleShowSendInteractions = () => {
        setShowSendInteractions(!showSendInteractions);
    }
    const getAllInteractions = async () => {
        setLoader(true);
        let url_suffix;
        if (usedfor == 'case') {
            url_suffix = `caseId=${caseId}`;
        } else if (usedfor == 'project') {
            url_suffix = `projectIdentifier=${projectId}`;
        }
        try {
            const response = await axios.get(`${BaseURL}/api/v1/projects/${localStorage?.getItem("userid")}/interaction-list?${url_suffix}`, Authorization_header());

            setInteractionData(response?.data?.data);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error("error :", error);
        }
    };

    useEffect(() => {
        getAllInteractions();
    }, [caseId, projectId]);

    useEffect(() => {
        setRowsPerPage(5);
        setCurrentPageNumber(0);

    }, [])
    useEffect(() => {
        const filtered = interactionData.filter((data) =>
            data?.interactionsIdentifier?.toLowerCase().includes(search.toLowerCase()) ||
            data?.projectId?.toLowerCase().includes(search.toLowerCase()) ||
            data?.projectName?.toLowerCase().includes(search.toLowerCase()) ||
            data?.status?.toLowerCase().includes(search.toLowerCase()) ||
            data?.spocEmail?.toLowerCase().includes(search.toLowerCase())
        );
        const newData = filtered?.slice(currentPageNumber * rowsPerPage, currentPageNumber * rowsPerPage + rowsPerPage);
        setFilteredData(newData);
    }, [interactionData, search, rowsPerPage, currentPageNumber, interactionData]);

    const handlePageChange = (event, value) => {
        setCurrentPageNumber(value);
    }
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    }
    const handleSearch = (value) => {
        setSearch(value);
    };
    return (
        <>
            <Box sx={{ borderTop: "1px solid #E4E4E4", p: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ px: 2, display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.5rem 0.3rem" }}>
                    <InputLabel sx={styles.label}>Project Interaction</InputLabel>
                    <InputBase type="text" placeholder="search..." onChange={(e) => { handleSearch(e.target.value) }} startAdornment={<InputAdornment position='start'><SearchIcon sx={styles.searchIconStyle} /></InputAdornment>} sx={{ ...styles.inputBase, width: "30%", alignItems: "right" }} />
                    <Box>
                        <Button
                            variant="contained"
                            sx={{ ...styles.uploadButtonStyle, mx: "10px" }}
                            onClick={handleOpen}
                        >
                            Update SPOC
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ ...styles.uploadButtonStyle, mx: "10px" }}
                            onClick={() => handleSendInteractionsClick("")}
                        >
                            Send Interactions
                        </Button>
                        <Button
                            variant="contained"
                            sx={styles.uploadButtonStyle}
                            onClick={handleSendReminderClick} >
                            Send Reminder
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <TableContainer sx={{ height: 300 }}>
                        <Table stickyHeader aria-label="simple table">
                            <MiniTableHeader tableData={tableData} usedfor={usedfor} />
                            {!loader && <CaseInteractionListingData handleShowInteractionListing={handleShowInteractionListing} handleInteractionId={handleInteractionId} rowData={filteredData} usedfor={usedfor} />}
                        </Table>
                        {loader && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                    minHeight: "380px",
                                }}
                            >
                                <CircularProgress sx={{ color: "#00A398" }} />
                            </div>
                        )}
                        {filteredData.length === 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                    minHeight: "380px",
                                }}
                            >
                                No Interaction found.
                            </div>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={interactionData?.length}
                        rowsPerPage={rowsPerPage}
                        page={currentPageNumber}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </Box>
            </Box>
            {/* <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success">
                    Automatic Send Interaction On
                </Alert>
            </Snackbar> */}

            <SpocIncludeProjectsModal open={showUpdateSpocModal} handleClose={handleClose} updatePurpose={UpdatePurposeRef.current} projects={interactionData} postUpdate={getAllInteractions} />
            <NewInteractionModal open={showSendInteractions} tableColumn={tableData} handleClose={handleShowSendInteractions} handleSendMail={handleSendMail} handleSurveysMailOpen={handleShowSendInteractions} handleConfirmationModalOpen={handleConfirmationModalOpen} interactionData={interactionData} interactionPurpose={interactionPurpose} />
        </>
    )
}
export default CaseInteractionListing

