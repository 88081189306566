import React from "react";
import FilterCustomAutocomplete from "../Common/FilterCustomAutocomplete";

const TitleSelector = ({ titles, title, setTitle }) => (
  <>
    <FilterCustomAutocomplete
      label=""
      placeholder="Select Title"
      options={titles?.map((title) => title) ?? []}
      value={title}
      onChange={(event, newValue) => {
        setTitle(newValue ?? "");
      }}
      inputValue={title}
      onInputChange={(event, newInputValue) => {
        setTitle(newInputValue ?? "");
      }}
      heading={"Title"}
    />
  </>
);

export default TitleSelector;
