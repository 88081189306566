import { Box, Paper } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import BarChart from "../../components/Common/BarChart";
import ProjectsTableStack from "../../components/Projects/ProjectsTableStack";
import { BaseURL } from "../../constants/Baseurl";
import { ProjectContext } from "../../context/ProjectContext";
import {
  DiffBwDays,
  createObjectFromArray,
} from "../../utils/helper/DiffBwDays";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import {
  getMonthYear,
  getTimeDifference,
} from "../../utils/helper/UpdateTimeDifference";
import { Authorization_header } from "../../utils/helper/Constant";
import { SheetsContext, projectsSheets } from "../../context/SheetsContext";

const chartPaperStyle = {
  p: 1,
  flex: 1,
  borderRadius: "20px",
  height: "300px",
  boxShadow: "0px 3px 6px #0000001F",
};

const layoutBoxStyle = {
  width: "98%",
  mx: "auto",
  display: "flex",
  mt: 2,
  gap: "20px",
};

function Projects() {
  const { projects, fetchProjects } = useContext(ProjectContext);
  const [projectRnD, setProjectRnd] = useState(null);
  const [projectTtc, setProjectTtc] = useState(null);
  const [projectUnc, setProjectUnc] = useState(null);
  const [rndPercentages, setRndPercentages] = useState(null);
  const [ttc, setTtc] = useState(null);
  const [uncertainHours, setUncertainHours] = useState(null);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [labelObj, setLabelObj] = useState(null);
  const { fetchProjectsSheets, projectsSheets } = useContext(SheetsContext);
  const [selectedTab, setSelectedTab] = useState("");
  // const [sheets, setSheets] = useState("");

  const fetchProjectKPIData = async () => {
    try {
      const response1 = await axios.get(
        `${BaseURL}/api/v1/projects/${localStorage.getItem(
          "userid"
        )}/1/projects-kpi`, Authorization_header()
      );
      setProjectRnd(response1.data.data?.rndPercent?.projectName);
      setRndPercentages(response1.data.data?.rndPercent?.rndPercentage);
      setProjectTtc(response1.data.data?.ttc?.projectName);
      setTtc(response1.data.data?.ttc?.ttc);
      setProjectUnc(response1.data.data?.uncertainHours?.projectName);
      setUncertainHours(response1.data.data?.uncertainHours?.uncertainHours);
    } catch (error) {
      console.error(error);
    }
  };

  const getSelectedTab = (tabName) => {
    setSelectedTab(tabName);
  }

  useEffect(() => {
    if (selectedTab === "Uploaded Sheets") {
    fetchProjectsSheets();
    } else if (selectedTab === "All Projects") {
      fetchProjects();
    }
  }, [Authorization_header, selectedTab])

  useEffect(() => {
    fetchProjectKPIData();
  }, [Authorization_header]);

  useEffect(() => {
    const timeDifference = getTimeDifference(projects, "modifiedTime");
    setLatestUpdateTime(timeDifference);
  }, [projects, Authorization_header]);

  useEffect(() => {
    setLabelObj(
      createObjectFromArray(
        DiffBwDays(ttc),
        ttc?.map((item) => getMonthYear(item))
      )
    );
  }, [ttc, Authorization_header]);

  const customLabel1 = {
    rotation: 0,
    format: "{text}%",
    overflow: "justify",
  };
  const customLabel2 = {
    rotation: 0,
    format: "{text}",
    overflow: "justify",
  };

  const customLabel3 = {
    rotation: 0,
    format: "{text}",
    overflow: "justify",
  };

  return (
    <>
      {useHasAccessToFeature("F013", "P000000008") && (
        <Paper
          sx={{
            width: "98.5%",
            mx: "auto",
            mt: 1,
            borderRadius: "25px",
            mb: 3,
            boxShadow: "0px 3px 6px #0000001F",
          }}
        >
          <ProjectsTableStack
            latestUpdateTime={latestUpdateTime?.difference}
            getSelectedTab={getSelectedTab}
            projectsSheets={projectsSheets}
          />
        </Paper>
      )}
    </>
  );
}

export default Projects;
