import { CircularProgress, Paper, Table, TableContainer } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CustomPagination from "../../components/Common/CustomPagination";
import TableHeader from "../../components/Common/TableHeader";
import TableIntro from "../../components/Common/TableIntro";
import ModalForm from "../../components/Timesheets/ModalForm";
import TimesheetTableBody from "../../components/Timesheets/TimesheetTableBody";
import { BaseURL } from "../../constants/Baseurl";
import { updateTimeDifference } from "../../utils/helper/UpdateTimeDifference";
import toast, { Toaster } from "react-hot-toast";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import { FilterListContext } from "../../context/FiltersListContext";
import { TimesheetContext } from "../../context/TimesheetContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { Authorization_header, token_obj } from "../../utils/helper/Constant";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";

const tableData = {
  columns: [
    "Timesheet Name",
    "Fiscal Year",
    "Account",
    "Status",
    "Uploaded On",
    "Uploaded By",
    "Total Hours",
  ],
  rows: [
    {
      id: 1,
      timesheetName: "TS_Oct23",
      month: "Oct 2023",
      project: "PR-000000049",
      company: "Apple Inc.",
      projectManager: "Ezra Romero",
      uploadedOn: "29/11/2023 12:35:12",
      uploadedBy: "Ezra Romero",
      nonRnD: "1321",
      RnD: "285",
      uncertainHrs: "0",
      reconciledHrs: "26",
    },
  ],
};

function Timesheets() {
  const { pinnedObject } = usePinnedData();
  const {
    timesheets,
    fetchTimesheets,
    timesheetFilterState,
    currentState,
    setCurrentState,
    loading
  } = useContext(TimesheetContext);
  const { clientList, fetchUserDetails, fetchClientList } = useContext(FilterListContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [timesheetData, setTimesheetData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [pinStates, setPinStates] = useState({
    "All Timesheets": false,
    "Recently Viewed": false,
  });

  const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  const currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const placeholderRow = {};
  while (currentData?.length < itemsPerPage) {
    currentData.push(placeholderRow);
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const appliedFilters = {
    Clients: timesheetFilterState.company,
    Month: timesheetFilterState.monthName,
    MinimumNonRnDHours: timesheetFilterState.nonRnDHours[0],
    MaximumNonRnDHours: timesheetFilterState.nonRnDHours[1],
    MinimumRnDHours: timesheetFilterState.rnDHours[0],
    MaximumRnDHours: timesheetFilterState.rnDHours[1],
    MinimumUncertainHours: timesheetFilterState.uncertainHours[0],
    MaximumUncertainHours: timesheetFilterState.uncertainHours[1],
    MinimumReconciledHours: timesheetFilterState.reconciledHours[0],
    MaximumReconciledHours: timesheetFilterState.reconciledHours[1],
  };
  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchTimesheets(filters);
    } else {
      toast.error("Please select at least one filter.");
    }
  };

  useEffect(() => {
    fetchClientList();
    setCurrentState(
      pinnedObject?.TIMESHEETS === "RV" ? "Recently Viewed" : "All Timesheets"
    );
  }, [Authorization_header]);

  useEffect(() => {
    const updatedPinStates = {
      "All Timesheets": pinnedObject.TIMESHEETS === "ALL",
      "Recently Viewed": pinnedObject.TIMESHEETS === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.TIMESHEETS]);

  useEffect(() => {
    const shouldFetchWithFiltersTimesheet =
      timesheetFilterState.companyId.length > 0;
    if (shouldFetchWithFiltersTimesheet) {
      let timesheetOptions = {
        ...(timesheetFilterState.companyId.length > 0 && {
          client: timesheetFilterState.companyId,
        }),
        ...(timesheetFilterState.month.length > 0 && {
          month: timesheetFilterState.month,
        }),
        ...(timesheetFilterState.nonRnDHours && {
          nonRnDHoursMin: timesheetFilterState.nonRnDHours[0],
        }),
        ...(timesheetFilterState.nonRnDHours && {
          nonRnDHoursMax: timesheetFilterState.nonRnDHours[1],
        }),
        ...(timesheetFilterState.rnDHours && {
          rnDHoursMin: timesheetFilterState.rnDHours[0],
        }),
        ...(timesheetFilterState.rnDHours && {
          rnDHoursMax: timesheetFilterState.rnDHours[1],
        }),
        ...(timesheetFilterState.uncertainHours && {
          uncertainHoursMin: timesheetFilterState.uncertainHours[0],
        }),
        ...(timesheetFilterState.uncertainHours && {
          uncertainHoursMax: timesheetFilterState.uncertainHours[1],
        }),
        ...(timesheetFilterState.reconciledHours && {
          reconciledHoursMin: timesheetFilterState.reconciledHours[0],
        }),
        ...(timesheetFilterState.reconciledHours && {
          reconciledHoursMax: timesheetFilterState.reconciledHours[1],
        }),
      };
      fetchTimesheets(timesheetOptions);
    } else {
      fetchTimesheets();
    }
  }, [currentState, Authorization_header]);

  const handleFormSubmit = async (formData) => {
    const apiUrl = `${BaseURL}/api/v1/timesheets/${localStorage.getItem(
      "userid"
    )}/A01/timesheet-upload`;
    const data = {
      companyId: formData.company,
      timesheet: formData.file,
      month: formData.month,
      year: formData.year,
    };
    toast.loading("Uploading timesheet...");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);

      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        },
      });
      setTimesheetData(response?.data?.data);
      fetchTimesheets();
      handleModalClose();
      toast.dismiss();
      toast.success(response?.data?.message || "The file has been uploaded successfully and is currently in the processing queue.");
    } catch (error) {
      console.error("er", error);
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload timesheet.")
    }
  };

  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (timesheets) {
      const filteredData = timesheets?.filter(
        (task) =>
          task?.timesheetId?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.originalFileName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.uploadedBy?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.companyName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          `${task?.month} ${task?.year}`
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [timesheets, search]);

  useEffect(() => {
    const timeDifference = updateTimeDifference(timesheets, "uploadedOn");
    setLatestUpdateTime(timeDifference);
  }, [timesheets]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  const isUpload = useHasAccessToFeature("F018", "P000000002");
  const isSearch = useHasAccessToFeature("F018", "P000000009");

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Timesheets"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Timesheets"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      TIMESHEETS: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);
      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Timesheets" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  return (
    <>
      {useHasAccessToFeature("F018", "P000000008") && (
        <Paper
          sx={{
            display: "flex",
            width: "98.5%",
            mx: "auto",
            mt: 1,
            flexDirection: "column",
            borderRadius: "20px",
            mb: 3,
            boxShadow: "0px 3px 6px #0000001F",
          }}
        >
          <TableIntro
            heading={
              pinnedObject?.TIMESHEETS === "RV"
                ? "Recently Viewed"
                : "All Timesheets"
            }
            btnName={"Upload"}
            page={"timesheet"}
            totalItems={filteredRows?.length || 0}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onUploadClick={handleUploadClick}
            onSearch={handleSearch}
            latestUpdateTime={latestUpdateTime}
            items={["All Timesheets", "Recently Viewed"]}
            onApplyFilters={applyFiltersAndFetch}
            appliedFilters={appliedFilters}
            createPermission={isUpload}
            searchPermission={isSearch}
            onSelectedItem={handleSelectedHeaderItem}
            isPinnedState={pinStates[currentState]}
            onPinClicked={() => togglePinState(currentState)}
          />
          <ModalForm
            open={modalOpen}
            handleClose={handleModalClose}
            handleSubmit={handleFormSubmit}
            type={"upload"}
            clients={clientList}
          />
          <TableContainer
            sx={{
              maxHeight: "82vh",
              overflowY: "auto",
            }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHeader tableData={tableData} />
              {!loading && <TimesheetTableBody
                data={currentData}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              />
              }
            </Table>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                <CircularProgress sx={{ color: "#00A398" }} />
              </div>
            )}
            {currentData.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                No timesheet found.
              </div>
            )}
          </TableContainer>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            changePage={handleChangePage}
            changeItemsPerPage={handleChangeItemsPerPage}
            minRows={20}
          />
        </Paper>
      )}
      <Toaster />
    </>
  );
}

export default Timesheets;
