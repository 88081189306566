import React from "react";
import { Slider, FormControl, Box, InputLabel } from "@mui/material";

function valuetext(value) {
  return `${value}`;
}

const styles = {
  slider: {
    color: "#b9e4c9",
    "& .MuiSlider-thumb": {
      height: "14px",
      width: "14px",
      backgroundColor: "#FFFFFF",
      border: "2px solid #00A398",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "inherit",
      },
    },
    "& .MuiSlider-track": {
      height: "16px",
      borderRadius: "4px",
      backgroundColor: "#00A398",
    },
    "& .MuiSlider-rail": {
      height: "16px",
      borderRadius: "10px",
      opacity: 0.5,
      backgroundColor: "#E4E4E4",
    },
  },
  label: {
    fontWeight: 500,
    color: "#404040",
    fontSize: "13px",
    mb: -0.5,
  },
  valueBox: {
    borderRadius: "20px",
    border: "1px solid #E4E4E4",
    width: "84px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#404040",
  },
};

const SliderInput = ({ min, max, value, onChange, label, minWidth = 120 }) => (
  <>
    <InputLabel sx={styles.label}>{label}</InputLabel>
    <FormControl sx={{ m: 1, minWidth: minWidth }}>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        getAriaValueText={valuetext}
        sx={styles.slider}
      />
    </FormControl>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={styles.valueBox}>{value[0]}</Box>
      <Box sx={styles.valueBox}>{value[1]}</Box>
    </Box>
  </>
);

export default SliderInput;
