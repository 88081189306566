import React from "react";
import { Link } from "react-router-dom";
import { TableBody, TableRow, TableCell } from "@mui/material";
import { formattedDate } from "../../utils/helper/FormatDatetime";

const cellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  textAlign: "center",
  py: 2,
  fontSize: "12px",
};

const cellLinkStyle = {
  ...cellStyle,
  color: "#00A398",
  textDecoration: "underline",
  cursor: "pointer",
};

function DocumentTableBody({ filledRows }) {
  const rows =
    filledRows?.length > 10
      ? filledRows
      : Array.from({ length: 10 }, (_, index) => filledRows?.[index] || null);

  return (
    <>
      <TableBody>
        {rows?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {/* <TableCell sx={cellStyle}>
              {getType(row?.documentName) &&
                iconMapping[getType(row?.documentName)?.toLowerCase()]}
            </TableCell> */}
            <TableCell sx={{ ...cellLinkStyle, textAlign: "left" }}>
              {/* <a
                href={row?.documentPath}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00A398" }}
              > */}
              {row?.documentName || ""}
              {/* </a> */}
            </TableCell>
            <TableCell sx={cellStyle}>
              <Link>
                {row?.relatedTo
                  ? row?.relatedTo
                    .toLowerCase()
                    .replace(/(^\w|[^a-zA-Z0-9]+(\w))/g, (match, p1, chr) => ' ' + p1.toUpperCase())
                    .trim()
                  : ""}
              </Link>
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#FD5707" }}>{row?.aistatus || ""}</TableCell>
            <TableCell sx={cellStyle}>
              {row?.createdTime ? formattedDate(row?.createdTime) : ""}
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#FD5707" }}>{row?.modifiedBy || ""}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export default DocumentTableBody;
