import React, { useEffect, useState } from 'react'
import CaseInteractionListing from "./CaseInteractionListing"
import CaseInteractionDetails from './CaseInteractionDetails';

const Interaction = ({ usedfor, caseId, projectId }) => {
    const [showIntercationListing, setShowInteractionListingPage] = useState(true);
    const [interactionId, setInteractionId] = useState();
    const [intrIndentifier, setIntrIndentifier] = useState();

    const handleShowInteractionListing = () => {
        setShowInteractionListingPage(!showIntercationListing);
    }

    const handleInteractionId = (id = null, indentifier=null) => {
        setInteractionId(id);
        setIntrIndentifier(indentifier);
    }

    useEffect(()=>{
        setShowInteractionListingPage(true);
    },[caseId,projectId]);

    
    return (
        <>
            {showIntercationListing ? <CaseInteractionListing handleShowInteractionListing={handleShowInteractionListing} handleInteractionId={handleInteractionId} usedfor={usedfor} caseId={caseId} projectId={projectId}/> : <CaseInteractionDetails handleShowInteractionListing={handleShowInteractionListing} interactionId={interactionId} intrIndentifier={intrIndentifier}/>}
        </>
    )
}

export default Interaction;