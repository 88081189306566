import React, { useEffect } from 'react';
import { BaseURL } from "../constants/Baseurl";
import axios from 'axios';
import FormatDatetime from '../utils/helper/FormatDatetime';
import { Authorization_header } from '../utils/helper/Constant';
import { useAuthContext } from './AuthProvider';
import toast from 'react-hot-toast';


export const CaseContext = React.createContext();

export const CaseContextProvider = ({ children }) => {
    const [caseData, setCaseData] = React.useState([]);
    const [selectedCase, setSelectedCase] = React.useState(null);
    const [detailedCase, setDetailedCase] = React.useState(null);
    const [reqCaseId, setReqCaseId] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [currency, setCurrency] = React.useState(null);
    const { logout } = useAuthContext();


    const getCaseById = async (id) => {
        try {
            const response1 = await axios.get(`${BaseURL}/api/v1/case/${localStorage.getItem(
                "userid"
            )}/${id}/casedetails`, Authorization_header());
            const singleCase = response1?.data?.data?.casedetails;
            setDetailedCase(singleCase);
        } catch (error) {
            if (error?.response?.data?.logout === true) {
                logout();
            }
            console.error("Error fetching case by ID:", error);
        }
    };

    const getAllCases = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BaseURL}/api/v1/case/${localStorage.getItem(
                "userid"
            )}/cases`, Authorization_header())
            const caseList = response?.data?.data?.caseDetails?.map((elemnt) => ({
                caseCode: elemnt.caseCode,
                caseId: elemnt.caseId,
                status: elemnt.status,
                caseType: elemnt.caseType,
                companyId: elemnt.companyId,
                companyName: elemnt.companyName,
                countryName: elemnt.countryName,
                contactId: elemnt.primaryContactId,
                contactName: elemnt.primaryContactName,
                caseRoleName: elemnt.caseRoleName,
                caseOwnerName: elemnt?.caseOwnerName,
                email: elemnt.primaryContactEmail,
                caseTypeDescription: elemnt.caseTypeDescription,
                createdOn: FormatDatetime(elemnt?.createdOn).split("T")[0].split(' ')[0],
            }))
            setCaseData(caseList);
            setLoading(false);
            return caseList;
        } catch (error) {
            if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
                toast.error("Session expired, you need to login again");
                logout();
            }
            setLoading(false);
        }
    };

    const getCaseOnLanding = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const value = queryParams.get('caseId');
        setReqCaseId(value);
        getCaseById(value);
    }
    // useEffect(() => {
    //     getCaseOnLanding();
    // }, [caseData])

    const handleSelectedCase = (c) => {
        setSelectedCase(c);
    }

    return (
        <CaseContext.Provider
            value={{
                getAllCases,
                caseData,
                selectedCase,
                handleSelectedCase,
                getCaseById,
                detailedCase,
                currency,
                loading,
                getCaseOnLanding
            }}
        >{children}
        </CaseContext.Provider>)
}

