import axios from "axios";
import React, { createContext, useState } from "react";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [contactData, setContactData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contactFilterState, setContactFilterState] = useState({
    companyId: [],
    title: [],
    company: "",
    titleName: "",
  });
  const [clearContactFilterCounter, setClearContactFilterCounter] = useState(0);

  const [clearContactFilterTrigger, setClearContactFilterTrigger] =
    useState(false);
  const [isContactFilterApplied, setIsContactFilterApplied] = useState(false);
  const [currentState, setCurrentState] = useState(
    pinnedObject?.CONT === "RV" ? "Recently Viewed" : "All Employees"
  );
  const { logout } = useAuthContext();

  const triggerContactClearFilters = () => {
    setClearContactFilterCounter((prevCounter) => prevCounter + 1);
  };

  const fetchContactData = async (options = {}) => {
    const queryParams = new URLSearchParams();
    setLoading(true);
    if (options.companyIds)
      queryParams.append("companyIds", JSON.stringify(options.companyIds));
    if (options.employeeTitles)
      queryParams.append(
        "employeeTitles",
        JSON.stringify(options.employeeTitles)
      );
    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);

    const queryString = queryParams.toString();
    const finalQueryString = queryString ? `${queryString}` : "";

    const url = `${BaseURL}/api/v1/contacts/${localStorage.getItem(
      "userid"
    )}/1/get-contacts?${finalQueryString}`;

    try {
      const response = await axios.get(url, Authorization_header());
      setContactData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      if (error?.response?.data?.logout === true || error?.resposne?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setLoading(false);
    }
  };

  // const fetchEmployeeData = async (options = {}) => {
  //   const queryParams = new URLSearchParams();
  //   setLoading(true);
  //   if (options.companyIds)
  //     queryParams.append("companyIds", JSON.stringify(options.companyIds));
  //   if (options.nonRnDHoursMin)
  //     queryParams.append("sheetName", options.sheetName);
  //   if (options.nonRnDHoursMax)
  //     queryParams.append("relatedTo", options.relatedTo);

  //   const queryString = queryParams.toString();
  //   const url = `${BaseURL}/api/v1/contacts/get-employee-sheets${queryString ? `?${queryString}` : ""}`;
  //   setContactFilterState(queryString);

  //   try {
  //     const response2 = await axios.get(url, Authorization_header());
  //     setEmployeeData(response2?.data?.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setError(error);
  //     if (error?.response2?.data?.logout === true || error?.resposne2?.data?.message === "") {
  //       logout();
  //     }
  //     setLoading(false);
  //   }
  // };

  return (
    <ContactContext.Provider
      value={{
        contactData,
        // employeeData,
        fetchContactData,
        // fetchEmployeeData,
        loading,
        error,
        contactFilterState,
        setClearContactFilterTrigger,
        setContactFilterState,
        setIsContactFilterApplied,
        clearContactFilterTrigger,
        isContactFilterApplied,
        triggerContactClearFilters,
        clearContactFilterCounter,
        currentState,
        setCurrentState,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
