import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TechnicalSummaryListing from './TechnicalSummaryListing';
import TechnicalSummaryDetail from './TechnicalSummaryDetail';

const TechnicalSummary = ({ usedfor, caseId, projectId }) => {
  const [showSummaryListingTab, setShowSummaryListingPage] = useState(true);
  const [TechnicalSummaryId, setTechnicalSummaryId] = useState("");

  const handleShowSummaryListing = () => {

    setShowSummaryListingPage(!showSummaryListingTab);
  }

  const getTechnicalSummaryId = (id = null) => {
    setTechnicalSummaryId(id);
  }

  useEffect(() => {
    setShowSummaryListingPage(true);
  }, [caseId, projectId]);

  return (
    <>
      <Box>
        {showSummaryListingTab ? <TechnicalSummaryListing handleShowSummaryListing={handleShowSummaryListing} getTechnicalSummaryId={getTechnicalSummaryId} usedfor={usedfor} caseId={caseId} projectId={projectId} /> : <TechnicalSummaryDetail handleShowSummaryListing={handleShowSummaryListing} TechnicalSummaryId={TechnicalSummaryId} usedfor={usedfor} caseId={caseId} projectId={projectId} />}
      </Box>
    </>
  )
}

export default TechnicalSummary