import React from "react";
import FilterCustomAutocomplete from "../Common/FilterCustomAutocomplete";

function AccYearSelector({
  accountingYear,
  setAccountingYear,
  disabled,
  error,
}) {
  return (
    <>
      <FilterCustomAutocomplete
        label=""
        placeholder="Select Fiscal Year"
        options={[
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
          "2025",
          "2026",
          "2027",
          "2028",
          "2029",
          "2030",
          "2031",
          "2032",
          "2033",
          "2034",
          "2035",
          "2036",
          "2037",
          "2038",
          "2039",
          "2040",
        ]}
        value={accountingYear}
        onChange={(event, newValue) => {
          setAccountingYear(newValue ?? "");
        }}
        inputValue={accountingYear}
        onInputChange={(event, newInputValue) => {
          setAccountingYear(newInputValue ?? "");
        }}
        heading={"Fiscal Year"}
      />
    </>
  );
}

export default AccYearSelector;
