import React from "react";
import { InputLabel, Box, Typography } from "@mui/material";
import CustomAutocomplete from "../../Common/CustomAutocomplete";

const DocumentTypeSelect = ({ docType, doc, setDoc, disabled, error }) => {
  return (
    <Box>
      <InputLabel
        sx={{
          color: "#404040",
          fontSize: "14px",
          fontWeight: 600,
          mb: "5px",
        }}
      >
        Document Type
      </InputLabel>
      <CustomAutocomplete
        label=""
        placeholder="Select Document Type"
        options={docType?.map((doc) => doc) ?? []}
        value={doc}
        onChange={(event, newValue) => {
          setDoc(newValue ?? "");
        }}
        inputValue={doc}
        onInputChange={(event, newInputValue) => {
          setDoc(newInputValue ?? "");
        }}
        disabled={disabled}
      />
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default DocumentTypeSelect;
