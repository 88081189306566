import React from "react";
import { TableCell, TableHead, TableRow, Checkbox } from "@mui/material";

const headerCellStyle = {
  fontSize: "13px",
  borderRight: "1px solid #ddd",
  whiteSpace: "nowrap",
  py: 1,
  textAlign: "left",
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#ececec",
};

const headerCheckboxStyle = {
  color: "#00A398",
  "&.Mui-checked": { color: "#00A398" },
};

const headerRowStyle = {
  backgroundColor: "rgba(64, 64, 64, 0.1)",
  position: "sticky",
  top: 0,
  zIndex: 1,
};

function TableHeader({ tableData, tableData2 }) {
  return (
    <>
      <TableHead>
        <TableRow sx={headerRowStyle}>
          {/* <TableCell sx={{...headerCellStyle, textAlign: page==="activity" ? "left" : "center"}}> */}
          {/* <Checkbox sx={headerCheckboxStyle} /> */}
          {/* Sl. No. */}
          {/* </TableCell> */}
          {tableData?.columns?.map((column, index) => (
            <TableCell
              key={index}
              sx={{
                ...headerCellStyle,
                textAlign: index === 0 ? "left" : "center",
              }}
            >
              {column}
            </TableCell>
          ))}
          {tableData2?.columns?.map((column, index) => (
            <TableCell
              key={index}
              sx={{
                ...headerCellStyle,
                textAlign: index === 0 ? "left" : "center",
              }}
            >
              {column}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default TableHeader;
