import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, InputLabel } from "@mui/material";
import EditableInput from "../../Common/EditableInput";
import axios from "axios";
import { BaseURL } from "../../../constants/Baseurl";
import { Authorization_header } from "../../../utils/helper/Constant";

const AccYears = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
];

const styles = {
  flexBox: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #E4E4E4",
    px: 2,
    pb: 2,
  },
  flexBoxItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 2,
    mt: 2,
  },
  flexBoxItem2: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    // gap: 2,
    mt: 2,
  },
  textStyle: {
    fontWeight: 600,
    mt: 1,
  },
  label: {
    mb: 1,
    color: "#404040",
    fontSize: "14px",
  },
  inputStyle: {
    mb: 2,
    "& .MuiInputBase-root": {
      borderRadius: "20px",
    },
    width: "100%"
  },
};

function BasicDetails({
  data,
  editMode,
  editedValues,
  handleEditChange,
  clientData,
  errors,
}) {
  const [Contacts, setContacts] = useState([]);
  const [Portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(
          `${BaseURL}/api/v1/contacts/${localStorage.getItem("userid")}/1/get-contacts`, Authorization_header()
        );
        const response1 = await axios.get(
          `${BaseURL}/api/v1/portfolios/${localStorage.getItem("userid")}/get-portfolios`, Authorization_header()
        );

        setContacts(response?.data?.data);
        setPortfolios(response1?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [localStorage?.getItem("keys")]);
  return (
    <Box sx={styles.flexBox}>
      <Typography sx={styles.textStyle}>Details</Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={styles.flexBoxItem}>
          <EditableInput
            label="Project Name"
            value={editedValues.projectName}
            onChange={(e) => handleEditChange("projectName", e.target.value)}
            disabled={!editMode}
            required={true}
            errors={errors.projectName}
          />
          <EditableInput
            label="Employee Blended Rate Per Hour"
            value={editedValues.EmpBlendedRatePerHour}
            onChange={(e) =>
              handleEditChange("EmpBlendedRatePerHour", e.target.value)
            }
            disabled={!editMode}
            type="number"
          />
          <EditableInput
            label="Status"
            value={editedValues.projectStatus}
            onChange={(e) => handleEditChange("projectStatus", e.target.value)}
            disabled={!editMode}
            type="select"
            selectOptions={["Active", "Inactive"]?.map((client) => ({
              id: client,
              name: client,
            }))}
          />
        </Box>
        <Box sx={styles.flexBoxItem}>
          <EditableInput
            label="Account"
            value={editedValues.companyId}
            onChange={(e) => handleEditChange("companyId", e.target.value)}
            disabled={!editMode}
            type="select"
            selectOptions={clientData?.map((client) => ({
              id: client?.companyId,
              name: client?.companyName,
            }))}
            required={true}
            errors={errors.companyId}
          />
          <EditableInput
            label="Portfolio"
            value={editedValues.projectPortfolio}
            onChange={(e) =>
              handleEditChange("projectPortfolio", e.target.value)
            }
            disabled={!editMode}
            type="select"
            selectOptions={Portfolios?.map((item) => ({
              id: item.portfolioId,
              name: item.name,
            }))}
          />
          <EditableInput
            label="SPOC Name"
            value={editedValues.spocName}
            onChange={(e) =>
              handleEditChange("projectManagerId", e.target.value)
            }
            disabled={!editMode}
            type="select"
            selectOptions={Contacts?.map((item) => ({
              id: item.contactId,
              name: item.firstName + " " + item.lastName,
            }))}
          />
        </Box>
        <Box sx={styles.flexBoxItem}>
          <EditableInput
            label="Project Type"
            value={editedValues.projectType || ""}
            onChange={(e) => handleEditChange("projectType", e.target.value)}
            disabled={!editMode}
            type="select"
            selectOptions={["Software Development", "Software Testing"]?.map(
              (item) => ({
                id: item,
                name: item,
              })
            )}
          />
          <EditableInput
            label="Industry"
            value={editedValues.projectsIndustry}
            onChange={(e) =>
              handleEditChange("projectsIndustry", e.target.value)
            }
            disabled={!editMode}
            type="select"
            selectOptions={["Software", "Telecom"]?.map((item) => ({
              id: item,
              name: item,
            }))}
          />
          <EditableInput
            label="Fiscal Year"
            value={editedValues.accountingYear}
            onChange={(e) => handleEditChange("accountingYear", e.target.value)}
            disabled={!editMode}
            type="select"
            selectOptions={AccYears?.map((item) => ({
              id: item,
              name: item,
            }))}
          />
        </Box>
        <Box sx={styles.flexBoxItem2}>
          <div>
            <InputLabel sx={styles.label}>Description</InputLabel>
          </div>
          <TextField
            multiline
            rows={1}
            variant="outlined"
            sx={styles.inputStyle}
            value={editedValues.description}
            onChange={(e) => handleEditChange("description", e.target.value)}
            disabled={!editMode}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BasicDetails;
