import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FormatDatetime from "../../utils/helper/FormatDatetime";
import CompanyTableCell from "../Common/CompanyTableCell";
import TimesheetTableCell from "../Common/TimesheetTableCell";

const cellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  textAlign: "left",
  fontSize: "13px",
  py: 1,
};

const cellLinkStyle = {
  ...cellStyle,
  color: "#00A398",
  textDecoration: "underline",
  cursor: "pointer",
};

function TimesheetTableBody({ data, currentPage, itemsPerPage }) {
  return (
    <>
      <TableBody>
        {data?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            <TimesheetTableCell
              name={row?.originalFileName}
              id={row?.timesheetId}
            />
            <TableCell sx={{ ...cellStyle, textAlign: "center" }}>
              {row?.accountingYear ? `FY ${+(row?.accountingYear) - 1}-${row?.accountingYear.slice(-2)}` : ""}
            </TableCell>
            <CompanyTableCell id={row?.companyId} name={row?.companyName} />
            <TableCell sx={cellStyle}>

              {row?.status
                ? row?.status
                  .toLowerCase()
                  .replace(/(^\w|[^a-zA-Z0-9]+(\w))/g, (match, p1, chr) => ' ' + p1.toUpperCase())
                  .trim()
                : ""}

            </TableCell>
            <TableCell sx={cellStyle}>
              {FormatDatetime(row?.uploadedOn) || ""}
            </TableCell>
            {/* <TableCell sx={{ ...cellLinkStyle, color: "#00A398" }}>
              <Link >{row?.uploadedBy || ""}</Link>
            </TableCell> */}
            <TableCell sx={{ ...cellStyle, textAlign: "center" }}>
              <Link>
                <span style={{ color: "#FD5707", cursor: "pointer" }}>{row?.uploadedBy || ""}</span>
              </Link>
            </TableCell>
            <TableCell sx={{ ...cellStyle, textAlign: "right" }}>
              <Link >{row?.totalhours?.toLocaleString('en-US') || ""}</Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

export default TimesheetTableBody;
