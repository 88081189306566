import { Box, CircularProgress, InputAdornment, InputBase, InputLabel, Table, TableContainer, TablePagination } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import MiniTableHeader from '../../Common/MiniTableHeader';
import CaseSummaryListing from './CaseSummaryListing';
import axios from 'axios';
import { BaseURL } from '../../../constants/Baseurl';
import { CaseContext } from '../../../context/CaseContext';
import { Authorization_header } from '../../../utils/helper/Constant';


const columns = [
    "Technical Summary ID",
    "Summary History",
    "Project ID",
    "Project Name",
    "Status",
    "Created On",
    "Created By",
];

const styles = {
    inputBase: {
        borderRadius: "20px",
        height: "40px",
        border: "1px solid #E4E4E4",
        pl: 1,
        width: "200px",
    },
    label: {
        color: "#404040",
        fontSize: "14px",
        fontWeight: 600,
    },
    searchIconStyle: {
        color: "#9F9F9F",
        ml: "3px",
        mr: "-3px",
        width: "20px",
        height: "20px",
    },
};

const TechnicalSummaryListing = ({ handleShowSummaryListing, getTechnicalSummaryId, usedfor, caseId, projectId }) => {
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [summaryData, setSummaryData] = useState([]);
    const [loader, setLoader] = useState(false);



    const getSummaryListing = async () => {
        let url_suffix;
        if (usedfor == 'case') {
            url_suffix = `caseId=${caseId}`;
        } else if (usedfor == 'project') {
            url_suffix = `projectIdentifier=${projectId}`;
        }
        setLoader(true);
        try {
            const response = await axios.get(`${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/summary-list?${url_suffix}`, Authorization_header());
            setSummaryData(response?.data?.data || []);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error(error);
        }
    };

    useEffect(() => {
        getSummaryListing();
    }, [caseId, projectId]);

    const handleSearch = (value) => {
        setSearch(value);
    };

    useEffect(() => {
        const filtered = summaryData.filter((data) =>
            data?.TechnicalSummaryIdentifier?.toLowerCase().includes(search.toLowerCase()) ||
            data?.ProjectId?.toLowerCase().includes(search.toLowerCase()) ||
            data?.ProjectName?.toLowerCase().includes(search.toLowerCase()) ||
            data?.TaxConsultantName?.toLowerCase().includes(search.toLowerCase()) ||
            data?.GeneratedOn?.toLowerCase().includes(search.toLowerCase()) ||
            data?.LastEditedOn?.toLowerCase().includes(search.toLowerCase())
        );
        const newData = filtered.slice(currentPageNumber * rowsPerPage, currentPageNumber * rowsPerPage + rowsPerPage);
        setFilteredData(newData);
    }, [summaryData, search, rowsPerPage, currentPageNumber]);

    const handlePageChange = (event, value) => {
        setCurrentPageNumber(value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    const filteredColumns = (usedfor === 'project')
        ? columns.filter(column => column !== "Summary History")
        : columns;

    const tableData = {
        columns: filteredColumns
    };

    return (
        <>
            <Box sx={{ borderTop: "1px solid #E4E4E4", p: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ px: 2, display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.3rem 0.3rem" }}>
                    <InputLabel sx={styles.label}>Technical Summary</InputLabel>
                    <InputBase type="text" placeholder="search..." onChange={(e) => { handleSearch(e.target.value) }} startAdornment={<InputAdornment position='start'><SearchIcon sx={styles.searchIconStyle} /></InputAdornment>} sx={{ ...styles.inputBase, width: "40%", alignItems: "right" }} />
                </Box>
            </Box>

            <TableContainer sx={{ height: 300 }}>
                <Table stickyHeader aria-label="simple table">
                    <MiniTableHeader tableData={tableData} usedfor={usedfor} />
                    <CaseSummaryListing handleShowSummaryListing={handleShowSummaryListing} getTechnicalSummaryId={getTechnicalSummaryId} rowData={filteredData} usedfor={usedfor} />
                </Table>
                {loader && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", minHeight: "380px" }}>
                        <CircularProgress sx={{ color: "#00A398" }} />
                    </div>
                )}
                {filteredData.length === 0 && !loader && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", minHeight: "380px" }}>
                        No Technical Summary found.
                    </div>
                )}
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={summaryData.length}
                rowsPerPage={rowsPerPage}
                page={currentPageNumber}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default TechnicalSummaryListing;

