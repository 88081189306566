import { useEffect, useState } from "react";
import { BaseURL } from "../../../constants/Baseurl";
import axios from "axios";
import { CircularProgress, Table, TableContainer, TablePagination } from "@mui/material";
import PanelTableHeader from "./PanelTableHeader";
import PanelTableBody from "./PanelTableBody";
import { Authorization_header } from "../../../utils/helper/Constant";

const tableData = {
    columns: [
        "Task Date",
        "Project ID",
        "Task Description",
        "Employees",
        "R&D Classification",
        "Hourly Rate",
        "Task Effort(Hrs)",
        "Total Expense",
        "R&D Expense",
        "Created By",
        "Created Time",
        "Modified By",
        "Modified Time",
        "Task ID",
    ],
    rows: [
        {
            id: 1,
            taskDate: "12/10/2023",
            taskID: "43568",
            taskDesc: "Task Description",
            employee: "Ezra Romero",
            rndClassification: "R&D",
            hourlyRate: "$ 70.00",
            taskEffort: "8.00",
            totalExpense: "$ 560.00",
            rndExpense: "$ 560.00",
        },
    ],
};

const TimesheetTask = ({ data, timesheetId, searchInput }) => {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(10);
    const [isLoading, setisLoading] = useState(false);
    const [timesheetData, setTimesheetData] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);

    const fetchTimesheetDetails = async (timesheetId) => {
        const companyId = data?.companyId;
        const apiUrl = `${BaseURL}/api/v1/timesheets/${localStorage.getItem(
            "userid"
        )}/${companyId}/${timesheetId}/get-timesheet-details?page=${page}&limit=${itemsPerPage}`;

        setisLoading(true);
        try {
            const response = await axios.get(apiUrl, Authorization_header());

            setTimesheetData(response?.data?.data?.tasks);
            setTotalPages(response?.data?.data?.totalCount / itemsPerPage);
            setTotalCount(response?.data?.data?.totalCount);
        } catch (error) {
            console.error("Error:", error.message);
        }
        setisLoading(false);
    };

    useEffect(() => {
        if (timesheetId) {
            fetchTimesheetDetails(timesheetId);
        }
    }, [timesheetId, page, itemsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeItemsPerPage = (newItemsPerPage) => {
        setItemsPerPage(newItemsPerPage);
        // setPage(1);
    };

    useEffect(() => {
        if (timesheetData) {
            const filteredData = timesheetData?.filter(
                (task) =>
                    task?.taskDescription
                        ?.toLowerCase()
                        ?.includes(searchInput?.toLowerCase()) ||
                    task?.firstName?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    task?.projectId?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    task?.taskClassification
                        ?.toLowerCase()
                        ?.includes(searchInput?.toLowerCase()) ||
                    task?.taskId?.toString()?.includes(searchInput)
                // Add more conditions as needed
            );
            setFilteredRows(filteredData);
        }
    }, [timesheetData, searchInput]);
    return (
        <>
            <TableContainer sx={{ maxHeight: "60vh", overflow: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                    <PanelTableHeader tableData={tableData} />
                    {!isLoading && <PanelTableBody filledRows={filteredRows} />}
                </Table>
                {isLoading && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                            minHeight: "47vh",
                        }}
                    >
                        <CircularProgress sx={{ color: "#00A398" }} />
                    </div>
                )}
                {filteredRows.length === 0 && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                            minHeight: "380px",
                        }}
                    >
                        No task found.
                    </div>
                )}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 50, 100, 200]}
                component="div"
                count={totalCount}
                rowsPerPage={itemsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    borderRadius: "20px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
                }}
            />
        </>
    )
}

export default TimesheetTask;