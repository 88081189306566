import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthProvider";

const tokens = localStorage.getItem('tokens');
const token_obj = JSON.parse(tokens);
// const Authorization_header() = {headers: { Authorization: `Bearer ${token_obj.accessToken}`}};

function Authorization_header(){
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return (
        { headers: { Authorization: `Bearer ${token_obj.accessToken}` } }
    )
}

const Check_Authorised = (message) => {
    const logout = useAuthContext();
    if (message === "session timed out") {
        logout();
    }
}

export { Authorization_header,token_obj, Check_Authorised};
