import axios from "axios";
import React, { createContext, useState } from "react";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [clientData, setClientData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientFilters, setClientFilters] = useState({
    location: "",
    NoOfProjects: [1, 500],
    type: "",
  });
  const [clearClientFilterTrigger, setClearClientFilterTrigger] =
    useState(false);
  const [isClientFilterApplied, setIsClientFilterApplied] = useState(false);
  const [currentState, setCurrentState] = useState(pinnedObject?.CLNT || "All");
  const { logout } = useAuthContext();

  const triggerClientClearFilters = () => {
    setClearClientFilterTrigger((prev) => !prev);
  };

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  const applySort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/get-company-list`;
    try {
      toast.loading("Fetching client data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url,config)
      toast.dismiss();
      console.log("response for fetching clients :", response?.data?.data);
      setClientData(response?.data?.data);
      toast.success( response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching client data");
      console.error("Error in fetching client data : ",error);
    }
  }

  const fetchClientData = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (options.companyType)
      queryParams.append("companyType", options.companyType);
    if (options.minProjectsCount)
      queryParams.append("minProjectsCount", options.minProjectsCount);
    if (options.maxProjectsCount)
      queryParams.append("maxProjectsCount", options.maxProjectsCount);
    if (options.location) queryParams.append("location", options.location);
    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/company/${localStorage.getItem(
      "userid"
    )}/get-company-list${queryString ? `?${queryString}` : ""}`;
    try {
      const response = await axios.get(url, Authorization_header());
      setClientData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("error :",error);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };



  const contextValue = {
    clientData,
    loading,
    error,
    fetchClientData,
    applySort,
    isClientFilterApplied,
    setIsClientFilterApplied,
    clearClientFilterTrigger,
    clientFilters,
    setClientFilters,
    triggerClientClearFilters,
    setCurrentState,
    currentState,
  };

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};
