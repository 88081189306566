import React from "react";
import { Box, Table, TableContainer } from "@mui/material";
import UpdationDetails2 from "../Common/UpdationDetails2";
import MiniTableHeader from "../Common/MiniTableHeader";
import { HiFilter } from "react-icons/hi";
import ContactsRndTableBody from "./ContactsRndTableBody";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";

const tableData = {
  columns: [
    "Project Name",
    "Project ID ",
    "Total R&D Hours",
    "Hourly Rate",
    "R&D Expense",

  ],
  rows: [
    {
      id: 1,
      projectId: "",
      timesheet: "",
      month: "",
      rndHours: "",
      hourlyRate: "",
      rndExpense: "",
    },
  ],
};

const filterIcon = {
  color: "white",
  borderRadius: "50%",
  backgroundColor: "#00A398",
  fontSize: "32px",
  padding: "5px",
  marginRight: "16px",
};

const RndExpenseTab = ({ data, modifiedBy, latestUpdateTime }) => {
  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />
          {/* {useHasAccessToFeature("F036", "P000000009") && (
            <HiFilter style={filterIcon} />
          )} */}
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            maxHeight: "50vh",
            overflowX: "auto",
            // scrollbarWidth: "none",
            // msOverflowStyle: "none",
            // "&::-webkit-scrollbar": {
            //   display: "none",
            // },
          }}
        >
          <Table stickyHeader aria-label="simple table">
            <MiniTableHeader tableData={tableData} />
            <ContactsRndTableBody filledRows={data} />
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default RndExpenseTab;
