import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Drawer, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DocumentContext } from "../../context/DocumentContext";
// import CompanyFilters from "../Companies/CompanyFilters";
import DocumentFilters from "../Documents/DocumentFilters";
import ActionButton from "../FilterComponents/ActionButton";
// import TitleSelector from "../FilterComponents/TitleSelector";
// import MonthSelector from "../FilterComponents/MonthSelector";
import { ClientContext } from "../../context/ClientContext";
import { PortfolioContext } from "../../context/PortfolioContext";
import { ProjectContext } from "../../context/ProjectContext";
import PortfolioFilters from "../Portfolios/PortfolioFilters";
// import ProjectFilters from "../Projects/ProjectFilters";
import WorkbenchFilters from "../Reconciliations/WorkbenchFilters";
import { FilterListContext } from "../../context/FiltersListContext";
import TimesheetFilters from "../Timesheets/TimesheetFilter";
import { TimesheetContext } from "../../context/TimesheetContext";
import { WorkbenchContext } from "../../context/WorkbenchContext";
import ContactFilters from "../Contacts/ContactFilters";
import ActivityFilter from "../Activity/ActivityFilter";
import { ContactContext } from "../../context/ContactContext";
import { ActivityContext } from "../../context/ActivityContext";
import UserManagementFilters from "../Settings/UserManagement/UserManagementFilters";
import { UserManagementContext } from "../../context/UserManagementContext";
import { EmployeeContext } from "../../context/EmployeeContext";
import CompanyFilters from "../Projects/CompanyFilters";
import { Authorization_header } from "../../utils/helper/Constant";
import axios from "axios";
import { BaseURL } from "../../constants/Baseurl";
import toast from "react-hot-toast";

const styles = {
  drawerPaper: {
    "& .MuiDrawer-paper": {
      borderRadius: "20px 0px 0px 20px",
    },
  },
  label: {
    fontWeight: 500,
    color: "#404040",
    fontSize: "13px",
  },
  formControl: {
    m: 1,
    minWidth: 120,
    borderRadius: "20px",
  },
  select: {
    borderRadius: "20px",
    height: "30px",
    width: "240px",
    // marginLeft: "7px",
  },
  slider: {
    color: "#b9e4c9",
    "& .MuiSlider-thumb": {
      height: "14px",
      width: "14px",
      backgroundColor: "#FFFFFF",
      border: "2px solid #00A398",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "inherit",
      },
    },
    "& .MuiSlider-track": {
      height: "16px",
      borderRadius: "4px",
      backgroundColor: "#00A398",
    },
    "& .MuiSlider-rail": {
      height: "16px",
      borderRadius: "10px",
      opacity: 0.5,
      backgroundColor: "#E4E4E4",
    },
  },
  valueBox: {
    borderRadius: "20px",
    border: "1px solid #E4E4E4",
    width: "84px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#404040",
  },
  button: {
    width: "60px",
    height: "30px",
    borderRadius: "20px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "13px",
    color: "white",
    marginRight: "8px",
  },
  title: {
    fontSize: "13px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  valueBoxStyle: { display: "flex", justifyContent: "space-between" },
};

function FilterPanel({
  handleClose,
  open,
  page,
  documentType,
  onApplyFilters,
}) {
  const { clientList } = useContext(FilterListContext);
  const [companySortParam, setCompanySortParam] = useState({ sortField : "", sortOrder: "" });
  const {
    docFilterState,
    setDocFilterState,
    triggerClearFilters,
    setIsFilterApplied,
  } = useContext(DocumentContext);
  const {
    portfolioFilters,
    setPortfolioFilters,
    triggerPortfolioClearFilters,
    setIsPortfolioFilterApplied,
  } = useContext(PortfolioContext);
  const {
    fetchProjects,
    projectFilterState,
    setProjectFilterState,
    triggerProjectClearFilters,
    setIsProjectFilterApplied,
    // applySort,
  } = useContext(ProjectContext);
  const {
    clientFilters,
    setClientFilters,
    setIsClientFilterApplied,
    triggerClientClearFilters,
    applySort,
  } = useContext(ClientContext);
  const {
    timesheetFilterState,
    setTimesheetFilterState,
    triggerTimesheetClearFilters,
    setIsTimesheetFilterApplied,
  } = useContext(TimesheetContext);
  const {
    workbenchFilterState,
    setWorkbenchFilterState,
    triggerWorkbenchClearFilters,
    setIsWorkbenchFilterApplied,
  } = useContext(WorkbenchContext);
  const {
    contactFilterState,
    setContactFilterState,
    triggerContactClearFilters,
    setIsContactFilterApplied,
  } = useContext(ContactContext);
  const {
    employeeFilterState,
    setEmployeeFilterState,
    triggerEmployeeClearFilters,
    setIsEmployeeFilterApplied,
  } = useContext(EmployeeContext);
  const {
    activityFilterState,
    setActivityFilterState,
    triggerActivityClearFilters,
    setIsActivityFilterApplied,
  } = useContext(ActivityContext);
  const {
    userFilterState,
    setUserFilterState,
    triggerUserClearFilters,
    setIsUserFilterApplied,
    isUserFilterApplied,
  } = useContext(UserManagementContext);

  let documentOptions;
  useEffect(() => {
    const shouldFetchWithFilters =
      docFilterState.companyId.length > 0 ||
      docFilterState.projectId ||
      docFilterState.document;

    if (shouldFetchWithFilters) {
      documentOptions = {
        ...(docFilterState.companyId.length > 0 && {
          companyIds: docFilterState.companyId,
        }),
        ...(docFilterState.projectId && {
          relationId: docFilterState.projectId,
        }),
        ...(docFilterState.document && {
          documentType: docFilterState.document,
        }),
      };
    }
  }, [docFilterState]);

  const getCompanySortParams = ({sortField, sortOrder}) => {
    setCompanySortParam({sortField: sortField, sortOrder: sortOrder});
  }

  let portfolioOptions;
  useEffect(() => {
    const shouldFetchWithFiltersPortfolio =
      portfolioFilters.companyId.length > 0;

    if (shouldFetchWithFiltersPortfolio) {
      portfolioOptions = {
        ...(portfolioFilters.companyId.length > 0 && {
          companyIds: portfolioFilters.companyId,
        }),
        ...(portfolioFilters.NoOfProjects && {
          minProjects: portfolioFilters.NoOfProjects[0],
        }),
        ...(portfolioFilters.NoOfProjects && {
          maxProjects: portfolioFilters.NoOfProjects[1],
        }),
      };
    }
  }, [portfolioFilters]);

  let projectsOptions;
  useEffect(() => {
    const shouldFetchWithFiltersProjects =
      projectFilterState.companyId.length > 0 ||
      projectFilterState.portfolioId.length > 0 ||
      projectFilterState.accountingYear.length > 0;

    if (shouldFetchWithFiltersProjects) {
      projectsOptions = {
        ...(projectFilterState.companyId.length > 0 && {
          companyId: projectFilterState.companyId,
        }),
        ...(projectFilterState.portfolioId.length > 0 && {
          portfolioId: projectFilterState.portfolioId,
        }),
        ...(projectFilterState.accountingYear.length > 0 && {
          accountingYear: projectFilterState.accountingYear,
        }),
      };
    }
  }, [projectFilterState]);

  let clientOptions;
  useEffect(() => {
    const shouldFetchWithFiltersClient =
      clientFilters.location ||
      clientFilters.type ||
      clientFilters.NoOfProjects.length > 0;
    if (shouldFetchWithFiltersClient) {
      clientOptions = {
        ...(clientFilters.location && {
          location: clientFilters.location,
        }),
        ...(clientFilters.NoOfProjects && {
          minProjectsCount: clientFilters.NoOfProjects[0],
        }),
        ...(clientFilters.NoOfProjects && {
          maxProjectsCount: clientFilters.NoOfProjects[1],
        }),
        ...(clientFilters.type && {
          companyType: clientFilters.type,
        }),
      };
    }
  }, [clientFilters]);

  let timesheetOptions;
  useEffect(() => {
    const shouldFetchWithFiltersTimesheet =
      timesheetFilterState.companyId.length > 0 ||
      (timesheetFilterState.month.length > 0 &&
        timesheetFilterState.month[0] !== "") ||
      timesheetFilterState.nonRnDHours.length > 0 ||
      timesheetFilterState.rnDHours.length > 0 ||
      timesheetFilterState.uncertainHours.length > 0 ||
      timesheetFilterState.reconciledHours.length > 0;
    if (shouldFetchWithFiltersTimesheet) {
      timesheetOptions = {
        ...(timesheetFilterState.companyId.length > 0 && {
          client: timesheetFilterState.companyId,
        }),
        ...(timesheetFilterState.month.length > 0 && {
          month: timesheetFilterState.month,
        }),
        ...(timesheetFilterState.nonRnDHours && {
          nonRnDHoursMin: timesheetFilterState.nonRnDHours[0],
        }),
        ...(timesheetFilterState.nonRnDHours && {
          nonRnDHoursMax: timesheetFilterState.nonRnDHours[1],
        }),
        ...(timesheetFilterState.rnDHours && {
          rnDHoursMin: timesheetFilterState.rnDHours[0],
        }),
        ...(timesheetFilterState.rnDHours && {
          rnDHoursMax: timesheetFilterState.rnDHours[1],
        }),
        ...(timesheetFilterState.uncertainHours && {
          uncertainHoursMin: timesheetFilterState.uncertainHours[0],
        }),
        ...(timesheetFilterState.uncertainHours && {
          uncertainHoursMax: timesheetFilterState.uncertainHours[1],
        }),
        ...(timesheetFilterState.reconciledHours && {
          reconciledHoursMin: timesheetFilterState.reconciledHours[0],
        }),
        ...(timesheetFilterState.reconciledHours && {
          reconciledHoursMax: timesheetFilterState.reconciledHours[1],
        }),
      };
    }
  }, [timesheetFilterState]);



  let workbenchOptions;
  useEffect(() => {
    const shouldFetchWithFilters =
      workbenchFilterState.companyId.length > 0 ||
      workbenchFilterState.projectId.length > 0 ||
      workbenchFilterState.monthName !== "" ||
      workbenchFilterState.timesheetId.length > 0;
    if (shouldFetchWithFilters) {
      workbenchOptions = {
        ...(workbenchFilterState.companyId.length > 0 && {
          companyId: workbenchFilterState.companyId,
        }),
        ...(workbenchFilterState.projectId.length > 0 && {
          projectId: workbenchFilterState.projectId,
        }),
        ...(workbenchFilterState.timesheetId.length > 0 && {
          timesheetId: workbenchFilterState.timesheetId,
        }),
        ...(workbenchFilterState.monthName !== "" && {
          timesheetMonth: [workbenchFilterState.monthName],
        }),
      };
    }
  }, [workbenchFilterState]);

  let contactOptions;
  useEffect(() => {
    const shouldFetchWithFiltersContact =
      contactFilterState.companyId.length > 0 ||
      contactFilterState.titleName !== "";
    if (shouldFetchWithFiltersContact) {
      contactOptions = {
        ...(contactFilterState.companyId.length > 0 && {
          companyIds: contactFilterState.companyId,
        }),
        ...(contactFilterState.titleName !== "" && {
          employeeTitles: [contactFilterState.titleName],
        }),
      };
    }
  }, [contactFilterState]);

  let activityOptions;
  useEffect(() => {
    const shouldFetchWithFiltersActivity =
      activityFilterState.interactionActivityType.length > 0 ||
      activityFilterState.interactionTo.length > 0 ||
      activityFilterState.status !== "" ||
      activityFilterState.from !== "" ||
      activityFilterState.to !== "" ||
      activityFilterState.modifiedTime.length > 0;
    if (shouldFetchWithFiltersActivity) {
      activityOptions = {
        ...(activityFilterState.interactionActivityType.length > 0 && {
          interactionActivityType: activityFilterState.interactionActivityType,
        }),
        ...(activityFilterState.interactionTo.length > 0 && {
          interactionTo: activityFilterState.interactionTo,
        }),
        ...(activityFilterState.modifiedTime.length > 0 && {
          modifiedTime: activityFilterState.modifiedTime,
        }),
        ...(activityFilterState.status !== "" && {
          status: [activityFilterState.status],
        }),
        ...(activityFilterState.from !== "" && {
          from: activityFilterState.from,
        }),
        ...(activityFilterState.to !== "" && {
          to: activityFilterState.to,
        }),
      };
    }
  }, [activityFilterState]);

  let userOptions;
  useEffect(() => {
    const shouldFetchWithFiltersUsers =
      userFilterState.clients.length > 0 ||
      userFilterState.title.length > 0 ||
      userFilterState.role.length > 0 ||
      userFilterState.status !== "";
    if (shouldFetchWithFiltersUsers) {
      userOptions = {
        ...(userFilterState.clients.length > 0 && {
          clients: userFilterState.clients,
        }),
        ...(userFilterState.title.length > 0 && {
          title: userFilterState.title,
        }),
        ...(userFilterState.role.length > 0 && {
          role: userFilterState.role,
        }),
        ...(userFilterState.status !== "" && {
          status: userFilterState.status,
        }),
        // ...(userFilterState.status !== "" && {
        //   status: [userFilterState.status],
        // }),
      };
    }
  }, [isUserFilterApplied]);

  const clearFilters = () => {
    if (page === "document") {
      setDocFilterState({
        companyId: [],
        projectId: "",
        document: "",
        company: "",
        project: "",
      });
      onApplyFilters({});
      triggerClearFilters();
      setIsFilterApplied(false);
    }
    if (page === "portfolio") {
      setPortfolioFilters({
        companyId: [],
        NoOfProjects: [1, 500],
        company: "",
      });
      onApplyFilters({});
      triggerPortfolioClearFilters();
      setIsPortfolioFilterApplied(false);
    }
    if (page === "project") {
      setProjectFilterState({
        companyId: [],
        portfolioId: [],
        accountingYear: [],
        accYear: "",
        company: "",
        project: "",
      });
      onApplyFilters({});
      triggerProjectClearFilters();
      setIsProjectFilterApplied(false);
    }
    if (page === "company") {
      setClientFilters({
        type: "",
        NoOfProjects: [1, 500],
        location: "",
      });
      onApplyFilters({});
      triggerClientClearFilters();
      setIsClientFilterApplied(false);
    }
    if (page === "timesheet") {
      setTimesheetFilterState({
        ...timesheetFilterState,
        companyId: [],
        company: "",
        monthName: "",
        month: [],
        nonRnDHours: [1, 5000],
        rnDHours: [1, 5000],
        uncertainHours: [1, 5000],
        reconciledHours: [1, 5000],
      });
      onApplyFilters({});
      triggerTimesheetClearFilters();
      setIsTimesheetFilterApplied(false);
    }
    if (page === "workbench") {
      setWorkbenchFilterState({
        companyId: [],
        projectId: [],
        timesheetId: [],
        month: [],
        company: "",
        project: "",
        timesheet: "",
        monthName: "",
      });
      onApplyFilters({});
      triggerWorkbenchClearFilters();
      setIsWorkbenchFilterApplied(false);
    }
    if (page === "contact") {
      setContactFilterState({
        companyId: [],
        title: [],
        company: "",
        titleName: "",
      });
      onApplyFilters({});
      triggerContactClearFilters();
      setIsContactFilterApplied(false);
      
    }
    if (page === "activity") {
      setActivityFilterState({
        interactionActivityType: [],
        interactionTo: [],
        modifiedTime: [],
        from: "",
        to: "",
        activityStatus: [],
        activityType: "",
        sentTo: "",
        date: "",
        dateFrom: "",
        dateTo: "",
        status: "",
      });
      onApplyFilters({});
      triggerActivityClearFilters();
      setIsActivityFilterApplied(false);
    }
    if (page === "user management") {
      setUserFilterState({
        clients: [],
        title: [],
        role: [],
        status: "",
        clientName: "",
        titleName: "",
        roleType: "",
        statusType: "",
      });
      onApplyFilters({});
      triggerUserClearFilters();
      setIsUserFilterApplied(false);
    }
  };


  const applyFilters = () => {
    if (page === "document") {
      onApplyFilters(documentOptions);
    }
    if (page === "portfolio") {
      onApplyFilters(portfolioOptions);
    }
    if (page === "project") {
      onApplyFilters(projectsOptions);

    }
    if (page === "company") {
      // onApplyFilters(clientOptions);
      applySort(companySortParam);
    }
    if (page === "timesheet") {
      onApplyFilters(timesheetOptions);
    }
    if (page === "workbench") {
      onApplyFilters(workbenchOptions);
    }
    if (page === "contact") {
      onApplyFilters(contactOptions);
    }
    if (page === "activity") {
      onApplyFilters(activityOptions);

    }
    if (page === "user management") {
      onApplyFilters(userOptions);
    }
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={styles.drawerPaper}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E4E4E4",
          px: 2,
          py: 1,
        }}
      >
        <Typography sx={styles.title}>
          {page === "company" ? "Account" : page} Filters
        </Typography>
        <CancelIcon
          sx={{
            color: "#9F9F9F",
            cursor: "pointer",
            "&: hover": { color: "#FD5707" },
          }}
          onClick={handleClose}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
          }}
        >
          {/*(page === "project" || page === "company") && <ProjectFilters clientData={clientList} />*/}
          {(/*page === "project" ||*/ page === "company") && <CompanyFilters clientData={clientList} getCompanySortParams={getCompanySortParams} />}

          {/* {page === "portfolio" && <PortfolioFilters clientData={clientList} />} */}
          {page === "document" && (
            <DocumentFilters
              documentClientData={clientList}
              documentType={documentType}
            />
          )}
          {/* {page === "company" && <CompanyFilters />} */}
          {page === "workbench" && <WorkbenchFilters />}
          {page === "timesheet" && <TimesheetFilters clientData={clientList} />}
          {page === "contact" && <ContactFilters />}
          {page === "activity" && <ActivityFilter />}
          {page === "user management" && <UserManagementFilters />}
        </Box>
        <Box sx={{ ...styles.valueBoxStyle, p: 2 }}>
          <Box sx={{ flex: 1 }}>
            <ActionButton
              label="Clear"
              color="#FD5707"
              onClick={clearFilters}
            />
          </Box>
          <Box
            sx={{
              flex: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <ActionButton
              label="Cancel"
              color="#9F9F9F"
              onClick={handleClose}
            />

            <ActionButton
              label="Apply"
              color="#00A398"
              onClick={applyFilters}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FilterPanel;
